.radio-tile-group .input-container .radio-button:checked+.radio-tile .radio-tile-label {
    color: white;
    /* background-color: #ff9900; */
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile .icon svg {
    fill: white;
    background-color: #299fd6;
}

.radio-tile-group .input-container .radio-button:checked+.radio-tile {
    background-color: #ff9900;
    border: 2px solid #ff9900;
    color: white;
    transform: scale(1.1, 1.1);
    border-radius: 100%;
}

.radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    margin: 0;
    padding: 12px;
}

.radio-tile-group .input-container .icon svg {
    fill: #299fd6;
    width: 3rem;
    height: 3rem;
}

.radio-tile-group .input-container .radio-tile {
    display: flex;
    background: #ddd;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    /* padding: 10px; */
    border-radius: 100%;
    transition: transform 300ms ease;
}

.radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 50px;
    margin: 0;
    cursor: pointer;
}

/* --- */

.radio-tile-group .input-container {
    position: relative;
    height: 50px;
    width: 50px;
    margin: 5px 15px;
}

.radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* ------- */

.input-container span{
    display: block;
    width: 60px;
    text-align: center;
    margin-top: 5px;
}