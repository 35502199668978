.nav-left-label.-admin{
    width: 100%;
}
.oervdj{
    border-left: 1px solid  #ccc
}
.js0osd{
    grid-template-columns: auto auto auto auto;
}
.hg0fhs{
    width: 150px;
    height: 100px;
}

.sdf89s8f{
    background-color: #fcff20;
    justify-content: center;
}

.ghdwqe{
    display: none;
    text-align: center;
    justify-content: center;
}

.cfg09ds{
    min-width: 150px;
    text-align: left;
}

.kjf80wef{
    width: 320px;
}

.iu49g9{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.dfo80df h1 {
    font-size: 1.8rem;
}

.dfo80df{
    width: 750px;
}

.gh97dt0{
    padding: 0 1.5rem;
}

.ij345dfg{
    grid-template-columns: 100px auto;
}
.-q-content-text-open>i{
    font-size: 1.889rem;
    color: #299fd6;
}

.k95jfhkd{
    font-size: 2rem;
    color: #299fd6
}
.pagination{
    margin-top: 1rem;
}
.nav-left.openmenu.mw768-menu {
    display: none
}

.ksol{
    width: 100px
}

.rrtcvcvb, .zxcvbn{
    display: none !important
}

.rtyu7u98{
    font-size: .899rem;
  
   
}

.dlfk045km{
    display: none;
}


/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 580px){
    .bglogin{
       background-image: url('../icons/bglogin.png');
       background-position-y: 250px;
       background-position-x: center;
       background-size: cover;
       background-repeat: no-repeat;
       position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;
    }
    .bglogin img{
        display: none;
    }
}

/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */


@media screen and (min-width: 1367px){
    .bglogin{
        background-size: cover;
    }
}
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 1281px){
    .report-chart > div {
        flex-wrap: wrap;
    }
    .chat-room > div > div > div > div > div > span, .chat-room > div > div > div > div > div > strong {
        margin-left: unset;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }    
    .chat-room > div > div > div > div > div {
        flex-wrap: wrap;
    }

    .chat-room > div > div > div{
        grid-template-columns: 40px calc(100% - 80px);
    }

    .chat-room > div > div > div > div > div {
        display: flex;
        flex-wrap: wrap;
    }

    .chat-room > div > div > div > div > div > span {
        margin-left: unset;
    }

    .faw0a{
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .addCompaint span, .addCompaintEnd span {
        font-size: 0 !important;
    }

    .government-detail-content > .detail {
        grid-template-rows: auto;
    }

    .government-detail-content > .detail > div:first-of-type > div {
        height: fit-content;
        position: absolute;
        top: -75px;
        right: 0;
    }

    .government-detail-content {
        position: relative;
        margin-top: unset;
    }

    .government-detail-content > .detail h2 {
        color: #000;
    }

    .government-detail-menu > div:last-of-type {
        display: flex !important;
        flex-wrap: wrap;
    }

    .government-detail-menu > div:last-of-type > div {
        margin-left: unset;
    }

    .government-detail-menu {
        overflow-x: auto;
    }

    .government-detail-menu > div {
        white-space: nowrap;
    }

    .admin-central > div {
        display: grid;
        grid-template-columns: 55px auto;
    }

    .admin-central-text {
        width: 100%;
        padding-left: 10px;
        overflow: hidden;
    }

    .admin-central-text span {
        width: calc(100% - 20px) !important;
        padding-left: unset;
        border-right: unset;
    }

    .admin-central-text h1{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
    }

    .dfo80df h1 {
        font-size: 1.5rem;
    }

    .admin-hotline > div {
        align-items: flex-start;
    }

    .dp-in-fx {
        display: block;
    }

    .width66, .width33 {
        width: 100%;
    }

    .admin-PanelWhi.history {
        margin-left: 0px;
    }

    .bg-closemenu{
        background: rgba(0, 0, 0, 0.75);
        display: flex;
        align-items: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        z-index: 9991;
    }
    .main-width{
        width: 900px;
    }

    .nav-head{
        width: 100%;
    }

    .navbar-list{
        flex: unset;
        max-width: max-content;
    }

    .admin-hotline-center{
        margin-bottom: -55px;
    }

    .page-news-70-title, .page-news-30-title{
        width: 100%;
    }

    .-news-title h1{
        font-size: 1rem;
    }

    .page-news-70{
        height: auto;
        max-height: 400px;
        position: relative;
    }

    .-divide70{
        width: 100%;
    }

    .-divide30{
        width: 100%;
        margin: unset;
        margin-top: 10px;
    }

    .page-news-30{
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;
    }

    .page-news-30-block{
        position: relative;
        width: calc(50% - 5px);
        height: 180px;
    }

    .page-news-mid .-today .-pic{
        width: 100%;
        height: 190px;
    }

    .page-news-mid .-today .-pic img {
        object-fit: cover;
        object-position: center;
        height: 100%;
     }

     .ij345dfg{
        grid-template-columns: auto;
    }

    .depart-grid > div > span, .depart-grid > div > a{
        width: 240px;
    }

    .rating-title {
        font-size: 1.5rem;
    }
    .js0osd{
        grid-template-columns: auto auto auto;
    }
    .oervdj{
        border-left: unset
    }
}

/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 901px) {
 

    .nav-head-admin .-right .-logout {
        padding: 0px;
    }

    .nav-head-admin .-right .-noti {
        width: 30px;
        height: 30px;
    }

    .mw768-menu {
        position: absolute;
        display: flex !important;
        right: -40px;
        font-size: 2rem;
        color: #fff;
        width: 40px;
        height: 60px;
        top: 0;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }
    /* .mw768-menu.fa-times {
        background-color: #fff;
        transition: all 1s
    } */
    /* .nav-left, .zxcvbn{
        display: block;
    } */
    .nav-head-admin .-right .-logout{
        border: unset;
        background: unset;
    }
    .nav-head-admin .-right .-logout span{
        display: none;
    }
    .nav-head-admin .-right .-logout i{
        display: block;
        font-size: 2.5rem
    }

    .nav-left, .zxcvbn{
        display: flex !important;
    }

    .nav-head-right-out p{
        display: none;
    }

    .dfiyughdj{
        display: none;
    }

    .NavHeader {
        justify-content: flex-start;
        display: flex;
        background-image: unset; /* The image used */
        background-color: #2090cd;
    }

    
    .nav-head-left, .diofguy {
        display: none
    }

    .rrtcvcvb{
        color: #fff;
        font-size: 1.3rem;
        font-weight: 500;
        /* width: 100%; */
        display: block !important;
        /* justify-content: flex-start; */
        text-align: left
    }
    .nav-head {
        height: 40px;
        /* width: 300px; */
        width: 100%;
        margin-left: 40px;
        display: flex;
        justify-content: space-between;
    }
    .nav-left.-admin {
        background-position: bottom;
        left: -220px;
        transition: left 1s;
        z-index: 999;
    }
    .nav-left.openmenu {
        left: 0px;
        transition: left 1s;
        z-index: 9999;
    }
    .nav-left.closemenu {
        left: -220px;
        position: absolute;
        transition: left 1s , position 10s;
    }
    .nav-head-admin .-left {
        padding-left: 30px;
    }
    .setCenter.-admin .panel-right {
        width: 100%;
        margin-left: 0px;
    }

    .main-width{
        width: 700px;
    }

    .oledkgdf{
        position: absolute;
        top: 80px;
        right: 5px;
    }
    .oledkgdf #callBack>span{
        display: none;
    }

    .-footer-contact > div{
        width: 200px;
    }

    .dropdownNoti{
        right: 60px;
    }

    .k95jfhkd{
        font-size: 1.2rem;
    }

    .-q-content-text-open>i{
        font-size: 1.2rem;
    }

    .SummaryComplaint .layout{
        width: 700px;
    }
    .df8we0{
        flex-wrap: wrap;
    }
    .df8we0>.chart-chat{
        margin-top: 1rem;
    }

    .depart-grid > div > span, .depart-grid > div > a {
        width: 220px;
    }


    .chat-room > div > div > div > div > div > strong {
        margin-right: 1rem;
    }

    .chart-y{
        overflow-x: hidden;
       }
    .chart > line {
        display: none;
    }
}


/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */


@media screen and (max-width: 579px){

    .i7o6ur{
     display:block !important;
    }
    .chart-y{
        overflow-x: hidden;
        font-size: .699rem;
       }
    .chat-room > div > div > div > div > div{
        display: grid;
        text-align: left;
    }
 

    .hg0fhs{
        width: 80px;
        height: 50px;
    }
   
    .depart-chat > div > div > div > div > span {
        margin-left: unset;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .faw0a {
        display: flex;
        flex-wrap: wrap;
    }
    .depart-chat > div > text, .chat-room > div > div > text {
        font-size: .899rem;
        
               
    }
    .depart-chat > div > div, .chat-room > div > div > div {
        grid-template-columns: 40px calc(100% - 45px);
    }

    .tf0fwe{
        top: -60px !important;
    }

    .ghdwqe{
        display: grid;
    }

    .fsddwe, .xzejgho{
        display: none;
    }

    .tu0ih5g>div{
        width: 40px !important;
        height: 40px !important;
    }
    .depart-chat-people .add, .add-wait{
        font-size: 1rem;
    }

    .depart-chat-people > div > .waiting{
        margin-top: 1.5rem;
        display: grid;
        text-align: left;

    }

    .b9g0poi>.w-50{
        width: 100% !important;
    }

    .xv0sf9{
        width: calc(100vw - 55px);
    }

    .b9g0poi>.w-50:last-of-type{
        margin-top: 1rem;
    }

    .dfFKjd{
        padding: unset;
        flex-wrap: wrap;
    }
    .dfFKjd>.w-50{
        width: 100% !important;
    }

    .-newsDetail-Carousel .carousel::-webkit-scrollbar {
        /* width */
        width: 4px;
    }

    .-newsDetail-Carousel .carousel {
        max-height: 150px;
    }

    .-newsDetail-Carousel .carousel .slide{
        height: 150px;
    }

    .cfg09ds{
        min-width: auto;
    }

    .btn-orange-absol {
        min-width: fit-content;
        padding: 0 4px;
        border-radius: 50%;
    }

    .btn-orange-absol>i{
        display: contents;
    }
    .btn-orange-absol>span{
        display: none;
    }

    .dfFKjd>div{
        justify-content: center !important;
    }

    .dfFKjd>div>div:first-of-type  {
        width: 100px !important;
        height: 100px !important;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .government-detail-officer > div > div > div> span {
        min-width: unset;
        max-width: unset;
        width: 100%;
    }

    .government-detail-officer > div > div:first-of-type {
        display: grid;
        grid-template-columns: 55px calc(100% - 80px);
        overflow-x: hidden;
    }

    .fgihtok, .evAlyz{
        width: 100%;
    }

    .ty7ju6{
        width: 100%;
    }

    .iu49g9{
        justify-content: flex-end;
        flex-wrap: wrap-reverse;
    }

    .government-detail-content > .detail h2 {
        font-size: 1.8rem;
    }
    .government-detail-content > .detail > div:last-of-type > div {
        width: 100%;
        max-width: unset;
        padding: 10px;
        margin: 5px 0;
    }

    .government-detail-content > .detail > div:last-of-type {
        flex-wrap: wrap;
    }

    .government-detail-content {
        height: fit-content;
    }
    .government-detail-content>div:first-of-type>div {
        width: 80px !important;
        height: 80px !important;
    }

    .dfo80df {
        width: 100%;
    }

    .news-carousel > div:first-of-type {
        height: 250px;
        width: 100%;
    }

    .modal-body-hotline > div > .img{
        display: none;
    }

    .modal-body-hotline > div > .fr, .modal-body-hotline > div > .LabelInput-panel{
        width: 100%;
    }

    .modal-body-hotline > div{
        flex-wrap: wrap;
    }

    .admin-hotline-text span {
        padding-left: 0;
        display: block;
        word-break: break-word;
    }

    .gh97dt0{
        padding: 0;
    }

    .contact-input > div {
        width: 100%;
    }

    .ChangeStatus .layout {
        padding: 10px;
    }

    .admin-PanelHead{
        flex-wrap: wrap;
    }

    .file-upload{
        max-width: 100px;
        min-width: unset;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        padding: .5rem 1rem !important;
    }

    .os0wlkm{
        padding: 1.2rem 1.2rem !important;
        font-size: 0rem !important;
    }
    .os0wlkm.jpg:before{
        left: 8px;
    }

    .depart-grid{
        grid-template-columns: auto;
    }

    .depart-grid > div > span, .depart-grid > div > a{
        width: 270px;
    }
    .admin-PanelContent {
        padding: 20px 5px;
    }
    .bglogin{
        /* background-image: linear-gradient(180deg, transparent 80% , #29a4db 81%); */
        /* background-position-y: bottom;
        background-size: contain;
        background-repeat: no-repeat; */
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        /* overflow: hidden; */
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .bglogin img{
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;
        margin-top: 175px;
    }

    .LabelInput-panel{
        margin-bottom: 1rem;
    }

    .-comp-form-cardno {
        margin-bottom: 1rem;
    }

    .ksol{
        width: 100%
    }

    .label-button {
       margin-left: 5%;
       margin-top: 2px;
    }
    
    .xvxvx{
        flex-direction: column-reverse;
        margin-top: 3.5rem;
        /* background-color: #1da4e3; */
        padding: 0 20px;
    }

    .label-login{
        border-left: unset;
    }

    .jkdfhkdjfg{
        width: 90%;
    }

    .center-grid {
        display: block;
        justify-content: center;
    }

    .login-img img {
        width: 180px;
    }

    #logoh2 {
        font-size: 2rem;
    }

    #logoh5 {
        font-size: 1.5rem;
    }

    /* .nav-head-right {
        right: 0;
        position: absolute;
    } */

    .admin-hotline-list {
        grid-template-columns: 100%;
    }

    .main-width{
        width: 100%;
        margin-top: 20px
    }

    .admin-hotline-center .row {
        width: 100%;
    }

    .admin-hotline-text {
        padding-left: 0px;
    }

    .admin-hotline-fx:after {
        width: 100%;
    }


    .tcmhmdf{
        display: flex;
        flex-wrap: wrap;
        padding: 0 15px;
    }

    .main-panel {
        justify-content: stretch;
        display: flex;
        width: 100%;
        padding: 0 15px;
        overflow-x: hidden;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .byFnul {
        flex-wrap: wrap;
    }

    .fx-ali-str {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .inputFile {
        display: flex;
        margin-top: 10px;
        width: 100%;
        justify-content: space-around;
    }

    .inputFile .-upImg {
        background: url('../icons/Asset14.png') 10px no-repeat;
    }
    
    .inputFile .-upDoc {
        background: url('../icons/Asset15.png') 10px no-repeat;
    }

    .inputFile .-upImg, .inputFile .-upDoc{
        padding: 5px 0 5px 20px;
    }

    .dfgh4yu {
        text-align: left;
        display: flex;
        word-break: break-word;
    }

    .-footer {
        width: 100%;
        flex-wrap: wrap;
    }

    .-footer-pos {
        width: auto;
        justify-content: center;
    }

    .chat-room-user .-chat-profile{
        font-size: .899rem;
    }

    .-comp-look-q-content{
        height: fit-content;
        margin-left: 5px;
    }
    .-comp-look-q-title{
        display: none;
    }

    .-q-content-text{
        margin-left: 0;
        opacity: 1;
        color: #000;
    }

    .j80lkjx{
        visibility: visible;
    }

    .-q-detail {
        padding-left: 20px;
    }

    .ij345dfg{
        margin-top: 1rem;
    }
    .ij345dfg>label:first-of-type{
        color: #299fd6;
    }

    .dlfk045km{
        display: block;
    }

    .-footer-contact > div{
        width: 300px;
    }

    .admin-hotline-fx{
        grid-template-columns: 60px auto;
    }

    .dropdownNoti{
        max-width: 260px;
    }

    .-q-content {
        transition: unset;
        /* transition: all 200ms cubic-bezier(0.600, -0.280, 0.735, 0.045); */
    }
    
    .-q-content-open {
        transition: unset;
    }
    
    .-q-content-text {
        transition: unset;
        /* transition: all .2s ease-in; */
    }
    
    .-q-content-text-open {
        transition: unset;
    }

    .-q-title{
        width: 100%;
    }

    .-q-content{
        position: relative;
        left: unset;
    }

    .fa-angle-right{
        transform: rotate(90deg);
    }

    .-q-content-text-open>i, .-q-content-text-open>span{
        display: none;
    }

    .-rate-b{
        margin: 30px 0px;
    }

    .radio-tile-group .input-container .radio-button, .radio-tile-group .input-container{
        height: 35px;
        width: 35px;
    }

    .-rate-textarea{
        width: 100%;
    }

    .j8rtdfnb{
        font-size: .899rem;
    }

    .page-news-mid .-today .-detail{
        display: none;
    }

    .page-news-mid .-today .-pic{
        width: 116px;
        height: 86px;
    }


    .page-news-mid .-today .-head h1{
        height: 48px;
        overflow: hidden;
    }

    .page-news-bottom .-today{
        display: flex;
        max-height: unset;
        flex-wrap: wrap;
    }

    .page-news-bottom .-today .-pic{
        width: 100%;
    }

    .-today .-head .-ellipsis{
        display: none;
    }

    .djhFaD{
        max-height: unset;
    }

    .page-news-30-block{
        height: 100px;
    }

    .SummaryComplaint .layout{
        width: 330px;
        padding: unset;
    }

    .SummaryComplaint .layout h2{
        font-size: 1.3rem;
    }

    .ert8eujn{
        margin-top: 1rem;
    }

    .news-carousel {
        width: calc(100% - 25px);
        overflow-x: hidden;
    }

    .xvxvx h3{
        text-shadow: 1px 1px 0px #fff;
    }
    .xvxvx span{
        margin: auto 5px;
    }
    .js0osd{
        grid-template-columns: auto auto auto;
    }

}

/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 468px){
    .ftdyugi, .depart-chat > div > text, .chat-room > div > div > text{
        justify-content: center;
      width: 100%;
      padding: 5px 0 0 0;
    }
    .chat-room > div > div, .depart-chat > div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap-reverse;
        margin-top: 1rem;
    }
    .chart-y{
        overflow-x: hidden;
       }

    .addCompaint:after {
        content: '' !important;
    }

    .government-detail-content > .detail h2 {
        font-size: 1.5rem;
    }

    .news-carousel > div:first-of-type {
        height: 150px;
    }

    .hGEFWC{
        top: -60px !important;
    }

    tspan{
        font-size: .889rem;
    }

    .rating-title {
        font-size: 1rem;
    }
  
    .depart-grid > div > span, .depart-grid > div > a{
        width: fit-content;
    }

    .trackingSmall .layout {
        width: 350px;
        padding: 20px;
    }

    .trackingSmall .layout .btn-blue {
        margin: unset;
        min-width: 100px;
        padding: 2px 25px;
    }
}

/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 376px){

    .government-detail-content > .detail h2 {
        font-size: 1.4rem;
    }

    .chart-x, .chart-y {
        display: none;
    }

    .chart-x{
    right: 0;
    width: 50px;
    }
   .chart-y{
    overflow-x: hidden;
   }
    tspan{
        font-size: .599rem;
    }

    .trackingSmall .layout {
        width: 300px;
    }

    .SummaryComplaint .layout{
        width: 300px;
    }

    .styles_overlay__CLSq-{
        padding: 0;
    }

    .js0osd{
        grid-template-columns: auto auto ;
    }

}

/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 321px){

    .js0osd{
        grid-template-columns: auto auto;
    }

    .addCompaint, .addCompaintEnd {
        margin-right: 5px !important;
    }
    
    .kjf80wef{
        width: 100%;
    }
    .government-detail-content>div:first-of-type>div {
        width: 70px !important;
        height: 70px !important;
    }
    .government-detail-content > .detail h2 {
        font-size: 1rem;
       white-space: pre-line;
    }

    .news-carousel {
        margin-top: 2rem;
    }

    .nav-head-admin .-left{
        font-size: 1rem;
    }

    .file-upload{
        max-width: 80px;
    }

    .admin-PanelWhi.history {
        min-width: unset;
    }
    .depart-grid > div > span, .depart-grid > div > a{
        width: auto;
    }

    .bglogin{
        height: 120%;
    }

    .radio-tile-group .input-container .radio-button, .radio-tile-group .input-container{
        height: 30px;
        width: 30px;
    }

    .radio-tile-group .input-container{
        margin: 5px 10px;
    }

    .radio-tile-group .input-container>span{
        display: none;
    }

    .SummaryComplaint .layout{
        width: 275px;
    }

    

    .trackingSmall .layout {
        width: 280px;
        padding: 10px 0px;
    }

    .trackingSmall .layout h1{
        font-size: .889rem;
        margin: 10px;
    }
    .trackingSmall .layout p{
        font-size: .899rem;
    }

    
}

