.form-control {
    box-shadow: none;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1rem;
    font-weight: 200;
}

@media screen and (max-width: 1600px){
    .form-control {
        font-size: 1rem;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.login-img {
    margin-top: 50px;
    text-align: center;
}

.label-button {
    text-align: center;
    margin-top: 72px;
}

.label-button .btn-blue, .label-button .btn-orange {
    width: 100%;
}

#bglogin {
    position: absolute;
    z-index: -1;
    width: 100%;
    bottom: 0;
}

#logoh2 {
    color: #29A4DB;
    font-size: 2.5rem;
}

#logoh5 {
    color: #eb871e;
    font-size: 2rem;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                              Login                                                                 */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.trackingSmall .layout {
    width: 450px;
    padding: 40px;
    text-align: center;
}

.trackingSmall .layout h1 {
    margin: 30px;
    font-size: 1.5rem;
}

.login-img img {
    width: 250px;
    margin-bottom: 30px;
}

.label-login {
    text-align: center;
    border-left: 1px solid #ddd;
}

.label-login h2 {
    margin: 20px 0;
}

.label-login .btn-blue {
    margin: 20px 0;
}

.login-User, .login-password {
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.login-User>input, .login-password>input {
    height: 40px;
    border-radius: 2px;
    padding-left: 46px;
}
.login-User>img, .login-password>img {
    height: 25px;
    margin-bottom: 1rem;
    margin-right: -35px;
    z-index: 99;
}

.Look-modal.trackingSmall {
    border-radius: 15px;
}

.trackingSmall .layout .btn-blue {
    margin: 15px;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                        style Scroll                                                                */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.styleScroll {
    overflow-y: auto;
}

.styleScroll::-webkit-scrollbar {
    /* width */
    width: 5px;
}

.styleScroll::-webkit-scrollbar-track {
    /* Track */
    background: #ccc;
    border-radius: 15px;
}

.styleScroll::-webkit-scrollbar-thumb {
    /* Handle */
    background: #299fd6;
    border-radius: 10px;
}

.styleScroll::-webkit-scrollbar-thumb:hover {
    /* Handle on hover */
    background: #17719b;
}

/* --------------------------------------------------- */

.-newsDetail-Carousel .carousel::-webkit-scrollbar {
    /* width */
    width: 10px;
}

.-newsDetail-Carousel .carousel::-webkit-scrollbar-track {
    /* Track */
    background: #f1f1f1;
}

.-newsDetail-Carousel .carousel::-webkit-scrollbar-thumb {
    /* Handle */
    background: #299fd6;
}

.-newsDetail-Carousel .carousel::-webkit-scrollbar-thumb:hover {
    /* Handle on hover */
    background: #17719b;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          news                                                                      */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.page-news-top {
    display: inline-flex;
    width: 1206px;
}

.page-news-70 {
    height: 374px;
    overflow: hidden;
    display: flex;
}

.page-news-70 img, .page-news-30 img {
    width: 100%;
    transition: all .6s;
    object-fit: cover;
    object-position: center
}

.page-news-30 {
    margin-bottom: 60px;
}

.page-news-30-block {
    margin-bottom: 2px;
    display: flex;
    height: 186px;
    overflow: hidden;
}

.page-news-70-title {
    position: absolute;
    align-self: flex-end;
    color: #fff;
    text-align: left;
    padding: 5px 10px;
    width: 844px;
    background-image: linear-gradient(#ff000000, #040404);
    display: inline-flex;
    justify-content: space-between;
    transition: all .6s;
    overflow: hidden;
    z-index: 1;
}

.page-news-70-title p {
    color: #fff;
    font-weight: 300;
}

.page-news-70-title .-news-title p {
    color: #fff;
    font-weight: 300;
}

.-news-title h1 {
    color: #fff;
}

.page-news-70-title .-news-read {
    align-self: center;
    display: flex;
    transition: all .6s;
    bottom: -85px;
    position: absolute;
    right: 15px;
}

.page-news-30-title .-news-read {
    align-self: center;
    transition: all .6s;
    bottom: -85px;
    position: absolute;
    right: 15px;
    opacity: 0;
    display: block;
}

.page-news-70-title .-news-read button, .page-news-30-title .-news-read button {
    background: transparent;
    border-radius: 18px;
    padding: 5px 5px;
    box-shadow: none;
    outline: none;
    border: 1px solid #fff;
    width: 85px;
    font-weight: 300;
}

.page-news-70-title .-news-read button:hover, .page-news-30-title .-news-read button:hover {
    background: #ffffff57;
    border-color: #ffffff57;
}

.page-news-30-title {
    position: absolute;
    align-self: flex-end;
    color: #fff;
    text-align: left;
    padding: 5px 10px;
    width: 338px;
    background-image: linear-gradient(#3b262600, #040404);
    display: inline-flex;
    justify-content: space-between;
    transition: all .6s;
    overflow: hidden;
    z-index: 1;
}

.page-news-30-title h1 {
    font-size: 1rem;
}

.page-news-30-title p {
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
    color: #fff;
    font-weight: 300;
}

/* ----------------------------- */

.page-news-big img, .page-news-30 img {
    width: 100%;
}

.page-news-big {
    height: 375px;
    overflow: hidden;
    display: flex;
}

.page-news-big-title {
    position: absolute;
    align-self: flex-end;
    color: #fff;
    text-align: left;
    padding: 5px 10px;
    width: 844px;
    background-image: linear-gradient(#ff000000, #040404);
}

.page-news-big-title p {
    color: #fff;
    font-weight: 300;
}

/* ----------------------------- */

.-divide7030 {
    width: 100%;
    /* display: inline-flex; */
    display: flex;
    flex-wrap: wrap;
}

.-divide70 {
    width: 70%;
}

.-divide30 {
    width: 28%;
    margin: 0 10px;
}

/* ----------------------------- */

.-headNews {
    border-bottom: 1px solid #299fd6;
    margin: 0 0 10px 0;
}

.-headPanel {
    border-bottom: 1px solid #299fd6;
    margin: 30px 0 10px 0;
}

.-headPanel p, .-headNews p {
    color: #fff;
    width: 100px;
    text-align: center;
    padding: 5px;
    background: #299fd6;
    margin: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* .page-news-mid, .page-news-bottom {
    width: 70%;
} */

.page-news-mid .-today {
    position: relative;
    width: 33.33%;
    padding: 5px;
    max-height: 500px;
    overflow-y: hidden;
    word-break: break-all;
    align-self: flex-start;
    display: inline-grid;
}

.page-news-mid .-today .-pic img {
    width: 100%;
}

.page-news-mid .-today .-head {
    margin-top: 10px;
    text-align: left;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
}

.page-news-mid .-today .-head h1, .page-news-bottom .-today .-head h1 {
    font-size: 1rem;
    margin-bottom: 0;
}

.page-news-mid .-today .-head p, .page-news-bottom .-today .-head span {
    color: #ccc;
    font-weight: 300;
    font-size: 1rem;
}

.page-news-mid .-today .-detail {
    text-align: left;
    font-size: 1rem;
    font-weight: 300;
    max-height: 127px;
    overflow-y: hidden;
}

/* --------------------------------------- */

.page-news-bottom .-today {
    position: relative;
    padding: 5px;
    max-height: 160px;
    overflow-y: hidden;
    word-break: break-all;
    align-self: flex-start;
    /* display: inline-flex; */
    display: flex;
    flex-direction: row;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
.page-news-bottom .-today:after {
   content: '';
   position: absolute;
   bottom: 0px;
   right: 20px;
   height: 30px;
   width: calc(100% - 270px);
   background-image: linear-gradient(transparent 0%,  #fff 50% )
}

.page-news-bottom .-today .-pic {
    width: 230px;
    min-width: 230px;
    overflow: hidden;
    /* display: grid; */
}

.page-news-bottom .-today .-pic img {
    width: 100%;
}

.page-news-bottom .-today .-head {
    padding: 10px 20px;
    display: grid;
    max-height: 215px;
    width: 100%;
    height: fit-content;
    overflow-y: hidden;
}

.page-news-bottom .-today .-head p {
    text-align: left;
    font-size: 1rem;
    font-weight: 300;
    max-height: 65px;
    overflow-y: hidden;
}

.page-news-bottom .-today:hover {
    background: #299fd614;
}

/* ------------------------------------------ */

.panel-facebook {
    background: #ddd;
}

/* ------------------ hover ------------------------ */

.page-news-70:hover .page-news-70-title {
    padding-top: 80px;
    transition: all .6s;
}

.page-news-70:hover .page-news-70-title .-news-read {
    bottom: 20px;
    transition: all .6s;
}

.page-news-30-block:hover .page-news-30-title {
    padding-top: 80px;
    transition: all .6s;
}

.page-news-30-block:hover .page-news-30-title .-news-read {
    bottom: 10px;
    transition: all .6s;
    display: block;
    opacity: 1;
}

.page-news-70:hover img, .page-news-30-block:hover img {
    transform: scale(1.2);
    transition: all .6s;
    z-index: -1;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                        News Deatil                                                                 */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.a_back {
    margin: 20px;
    color: #fff !important;
    text-decoration: none;
    line-height: 20px;
    cursor: pointer;
    font-size: 1rem;
}

.a_back:hover {
    text-decoration: underline !important;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                        News Deatil                                                                 */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.scrollnewsDe {
    /* max-height: 100vh; */
    max-height: 67vh;
    overflow-y: auto;
    box-shadow: inset -4px -4px 10px #00000038;
}

.-newsDetail {
    cursor: pointer;
    height: 100px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    margin: 5px 0;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.-newsDetail-pic {
    min-width: 140px;
    width: 140px;
    overflow: hidden;
    z-index: -1;
}

.-newsDetail-pic img {
    width: 100%;
    min-height: 100%;
  
}

.-newsDetail-text {
    padding-left: 5px;
    text-align: left;
}

.-newsDetail-text h1 {
    color: #333;
    font-size: 1rem;
    max-height: 60px;
    overflow: hidden;
}

.-newsDetail-text p {
    color: #ccc;
    font-size: 1rem;
    text-align: left;
}

.-newsDetail-Carousel {
    display: flex;
}

.-newsDetail-Carousel .carousel .slide {
    background: #F5F5F5;
    height: 382px;
}

.-newsDetail-Carousel .carousel {
    width: 140px;
    max-height: 382px;
    overflow-y: auto;
}
.-newsDetail-Carousel .carousel .slide img{
    height: 100%;
    object-fit: contain;
    object-position: center
}

.-newsDetail-Carousel .carousel .slider-wrapper>ul>li>div {
    /* height: 100%; */
    height: 382px;
}

.-newsDetail-Carousel .carousel .slider-wrapper>ul>li>div img {
    height: 100%;
}

.-divide70 ul.thumbs.animated {
    flex-direction: row;
    display: grid;
    padding: 0;
    transform: translate3d(0px, 0px, 0px) !important;
}

.-newsDetail-Carousel .carousel .thumbs-wrapper {
    margin: 0 10px;
}

.-newsDetail-Carousel .carousel .thumb {
    width: 100%;
    margin-right: 0;
    height: 70px;
}

.-newsDetail-Carousel .carousel .thumb img {
    height: 100%;
    object-fit: contain;
    border: 1px solid #eee;
    background-color: #eee;
}

.-newsDetail-Carousel .control-arrow.control-prev, .-newsDetail-Carousel .carousel .control-next.control-arrow {
    display: none;
}

.-newsDetail-70-text {
    margin: 20px 0;
    text-align: left;
}

/* .-newsDetail-70-text h1 {} */

.-newsDetail-70-text span {
    color: #ccc;
    font-size: 1rem;
    font-weight: 300;
}

.-newsDetail-70-text p {
    margin: 20px 0;
    font-weight: 300;
    font-size: 1rem;
}

/* -------------------------------- */

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid #299fd6 !important;
}

.-newsDetail:hover {
    background: #299fd614;
    text-decoration: none;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                        Q & A                                                                       */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.page-q-a {
    text-align: left;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.page-q-a h1 {
    font-size: 1rem;
}

.page-q-a p {
    font-weight: 300;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                        complaint                                                                   */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.-comp-form-input {
    cursor: pointer;
    margin-right: 5px !important;
}

/* -------------------------------------------------------------------------------------------------- */

.-comp-form-cardno {
    display: flex;
    align-items: baseline;
    /* padding-bottom: 20px;
    margin-bottom: 20px; */
}

.-comp-form-cardno label {
    width: 240px;
    text-align: left;
}

.-comp-form-cardno input {
    width: 200px;
}
/* -------------------------------------------------------------------------------------------------- */

.-comp-form-cardno-ad {
    display: flex;
    flex-direction: column;
    /* margin-left: -10px; */
}
@media screen and (min-width: 1600px){
    .-comp-form-cardno-ad {
         margin-left: 0px;
    }
}

.-comp-form-cardno-ad label {
    width: 240px;
    text-align: left;
}

.-comp-form-cardno-ad input {
    width: 100%;
}

/* -------------------------------------------------------------------------------------------------- */

.-comp-agree {
    display: inline-flex;
    align-items: baseline;
    text-align: left;
    margin: 15px 0;
    padding: 15px;
    background: #fffacf;
}

.-comp-agree label {
    cursor: pointer;
    font-weight: 300;
    margin: 0;
}

.-comp-agree input {
    margin: 0 5px 0 0;
}

/* -------------------------------------------------------------------------------------------------- */

.-comp-upload {
    width: calc(100% - 15px);
    margin-left: 15px;
    background: #eee;
    padding: 20px;
    max-height: 300px;
    min-height: 100px;
    overflow-y: auto;
}
.-comp-upload>.df34v0 {
    width: 100px;
}

@media screen and (max-width: 769px){
    .-comp-upload {
        width: calc(100% - 35px);
    }
}

.-comp-upload .-upList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    background: #fff;
}

.-comp-upload .-upList .-namefile {
    align-self: baseline;
    display: flex;
}

.-comp-upload .-upList .-namefile img {
    padding-right: 10px;
}

.-comp-upload .-upList .-namefile label {
    font-weight: 300;
    margin: 0;
}

/* -------------------------------------------------------------------------------------------------- */

.-comp-delete {
    cursor: pointer;
}

.-comp-btn-upload {
    display: grid;
    grid-gap: 15px;
}

.inputFile {
    display: grid;
    grid-gap: 10px;
}

.inputFile .-upImg {
    text-align: center;
    padding: 8px;
}

.inputFile input[type="file"] {
    display: none;
}

.inputFile .-upImg, .inputFile .-upDoc {
    box-shadow: none;
    width: 160px;
    font-weight: 200;
    padding: 8px 0 8px 25px;
    border-radius: 40px;
    border: 1px solid #ced4da;
    transition: all .2s;
    cursor: pointer;
}

.inputFile .-upImg:hover, .inputFile .-upDoc:hover {
    background-color: #299fd69c;
    border-color: #299fd6;
    color: #fff;
    transition: all .2s;
}

.inputFile .-upImg {
    background: url('../icons/Asset14.png') 15px no-repeat;
}

.inputFile .-upDoc {
    background: url('../icons/Asset15.png') 15px no-repeat;
}

.inputFile .-upImg:focus, .inputFile .-upDoc:focus {
    outline: none;
}

.SummaryComplaint .layout {
    width: 800px;
    padding: 40px;
    text-align: center;
}

.scrollDepart {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #ccc;
    /* color: #777; */
}

.selectTitleName:disabled {
    cursor: not-allowed;
    background: #ccc;
    color: #777;
}

/* -------------------------------------------------------------------------------------------------- */
 .selectSubtype>div>div{
    max-width: 100% !important;
    flex: unset;
 }

/* -------------------------------------------------------------------------------------------------- */
.-comp-look-content{
    display: flex;
    flex-wrap: wrap;
    width: 90%;
}
.-comp-look-content>div{
    padding-top: 10px
}
.-comp-look-content>div>div>label:first-child{
    font-weight: 500;
}
.-comp-look-content>div>div>label:last-child{
    font-weight: 300;
}
.-comp-look-content>div:nth-child(1)>div>label:last-child{
    color: #299fd6;
}
.-comp-look-content-nologin>div:first-of-type>div>label:last-child{
    color: #299fd6;
}
.-comp-look-content-nologin>div:nth-child(4)>div>label:last-child{
    color: #000000 !important;
}

.-comp-look-q-title{
    /* width: 50%; add by khawkriab */
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    line-height: 2;
    font-weight: 300;
    position: relative;
    padding: 10px;
    z-index: 200;
    margin-top: 2px;
    transition: all .2s ease-in;
    border-bottom: 1px solid #ddd;
    color: #29A4DB;
    cursor: pointer;
    
}
/* .-comp-look-q-title-text{

} */
.-comp-look-q-content{
    position: relative; 
    overflow: hidden;
    height: 30px;
    background-color: transparent;
    color: white;
    font-size: 1rem;
    text-align: center;
    z-index: 100;
    margin-top: -30px;
    margin-left: 25px;
    text-align: left;
    transition: all 200ms cubic-bezier(0.39, 0.58, 0.57, 1);
}

/* -------------------------------------------------------------------------------------------------- */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                               Rate                                                                                 */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/* .-rate {} */

.-rate-b {
    margin: 70px 0;
    display: grid;
    justify-content: center;
}

.-rate-b p {
    text-align: center;
    width: 515px;
}

.-rate-textarea {
    width: 500px;
    text-align: left;
}

.-rate-textarea label {
    font-size: 1rem;
    font-weight: 300;
    color: #333;
}

.-rate-textarea textarea {
    resize: none;
    height: 100px;
    width: 100%;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 4px;
}

.-rate-textarea textarea:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* .-rate-select {} */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                           lookcomplaint                                                                            */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.trackingBar {
    display: inline-flex;
    width: 100%;
}

.trackStatus {
    width: 140px;
    font-weight: 300;
    font-size: 1rem;
    height: 40px;
    background: #299fd6;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trackStatus:before {
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid green;
}

.trackStatus p:before {
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid green;
}

.trackStatus::before {
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid green;
}

.trackStatus p::before {
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid green;
}

.historyBlock {
    margin: 10px 0;
}

.historyBlock .datetime {
    width: 200px;
    font-weight: 400;
    color: #299fd6;
}

.colorLabel label {
    color: #333;
}

.historyBlock .status {
    font-weight: 300;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                       0                                                                                       */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

#callBack {
    color: #299fd6;
    margin: 0;
    cursor: pointer;
}

#callBack:hover {
    text-decoration: underline;
}

#close {
    margin-top: 10px;
    margin-left: 15px;
    cursor: pointer;
}

.detail-name p {
    color: #5E5E5E;
    font-size: 1rem;
}

.detail-name span {
    color: #29A4DB;
    font-weight: 300;
    font-size: 1rem;
}

.detail-name label {
    color: #BEBEBE;
    font-weight: 300;
    font-size: 1rem;
    margin-left: 5px;
}

/* ================================================================================================================================== */
/*                                                      lookcomplaint
/* ================================================================================================================================== */
.lookcomplaint-status>div>label:nth-child(2){
    color: #299fd6;
}


/* ================================================================================================================================== */
/*                                                      override calendar datepicker
/* ================================================================================================================================== */
.react-datepicker__input-container input {
    display: block;
    box-shadow: none;
    padding: 0 5px !important;
    font-size: 1rem;
    font-weight: 200;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-datepicker-wrapper {
    width: 49%;
}

.react-datepicker__header {
    padding-top: 5px !important;
    border-bottom: 1px solid #ccc !important;
}

.react-datepicker__day-names, .react-datepicker__week {
    font-size: 1rem !important;
    
}


.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #555 !important;
    margin: 1rem !important;
}

@media screen and (max-width: 1441px){
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        margin: .5rem !important;
    }
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff !important;
}

@media screen and (min-width: 1600px){
    .react-datepicker__header .form-control{
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
