* {
	font-family: 'Prompt', sans-serif;
}

*:focus {
	outline: none;
	text-decoration: none;
}

.Text-Head {
	font-size: 1.4rem;
	text-align: center;
}

.-text-line p {
	color: #5e5e5e;
	font-weight: 500;
}

.-text-line {
	margin: 20px 0 10px 0;
	border-bottom: 1px solid #bebebe;
}

p {
	font-size: 1rem;
	color: #5e5e5e;
	text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #5e5e5e;
}

label {
	font-size: 1rem;
}

@media screen and (max-width: 1600px) {
	label {
		font-size: 1rem;
	}
}

button {
	font-size: 1rem;
}

button:focus {
	outline: none;
	text-decoration: none;
}

.setminHeight {
	min-height: 66vh;
}

.swal-text {
	font-size: 1rem;
	text-align: center;
}
.swal-title {
	font-size: 1rem !important;
}

.d-grid {
	display: grid;
}

.error_text {
	color: #ff3300;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                search                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.search-input {
	display: flex;
	justify-content: center;
}

.search-input button {
	box-shadow: none;
	border: none;
	height: 40px;
	margin-left: -40px;
	background: none;
}

.search-input button:focus {
	outline: none;
}

.searchInput {
	display: block;
	box-shadow: none;
	padding: 20px;
	margin-bottom: 10px;
	font-size: 1.5rem;
	font-weight: 200;
	width: 350px;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 20px;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.searchInput:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* ------------------------------------------------------------------------------------------------------------------------------------*/
.admin-search-title {
	font-size: 1rem;
}
.admin-search-input {
	display: flex;
	justify-content: flex-start;
}

.admin-search-input button {
	box-shadow: none;
	border: none;
	height: 30px;
	background: none;
	margin-right: -35px;
	z-index: 9;
}

.admin-search-input button:focus {
	outline: none;
}
.admin-search-input .hint {
	position: relative;
	margin-right: 0px;
	margin-left: -35px;
	font-size: 1.8rem;
	color: #29a4db;
}
.admin-search-input .hint:focus {
	color: #006c9c;
}
.admin-search-input .hint:focus > div {
	display: flex;
	min-width: 350px;
	height: auto;
	bottom: -80px;
	border: 1px solid #0eaae7;
	padding: 10px;
	line-height: 1.5rem;
	right: 15px;
}
.admin-search-input .hint > div {
	/* display: none; */
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0px;
	right: 10px;
	width: 0px;
	height: 0px;
	color: #777;
	background-color: #fff;
	padding: 0 10px;
	font-size: 1rem;
	font-weight: 300;
	/* -webkit-transition: all 2s;  
  transition:width 2s, height 2s, border 10s, bottom 2s; */
	overflow: hidden;
}

@media screen and (max-width: 1600px) {
	.admin-search-input button {
		margin: auto -35px auto 0px;
		border: 2px solid transparent;
	}
	.admin-search-title {
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: .5rem;
	}
}
/* ------------------------------------------------------------------------------------------------------------------------------------*/
.admin-searchInput {
	display: block;
	box-shadow: none;
	padding: 15px;
	padding-left: 40px;
	font-size: 1rem;
	font-weight: 200;
	width: 350px;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 20px;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.admin-searchInput:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}
@media screen and (max-width: 1600px) {
	.admin-searchInput {
		font-size: 1rem;
	}
}
/* ------------------------------------------------------------------------------------------------------------------------------------*/
.admin-search-select {
	position: relative;
	width: 100%;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 1rem;
	font-weight: 300;
	/* -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; */
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                main                                                                */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.main-panel {
	justify-content: center;
	display: flex;
}

.main-width {
	width: 1206px;
	margin-top: 50px;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                display                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.dp-flex {
	display: flex;
}

.center-flex {
	display: flex;
	justify-content: center;
}

.center-grid {
	display: grid;
	justify-content: center;
}

.fx-space {
	display: flex;
	justify-content: space-between;
}

.fx-ali-str {
	display: flex;
	align-items: flex-start;
}

.inl-fx-space {
	display: inline-flex;
	justify-content: space-between;
}

.fx-direc-row {
	flex-direction: row;
	justify-content: space-between;
	display: flex;
	width: 90%;
}

.fx-direc-col {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.dp-in-fx {
	display: inline-flex;
}

.height100vh {
	height: 100vh;
}

.width100 {
	width: 100%;
}

.width66 {
	width: 50%;
}

.width33 {
	width: 33%;
}

@media screen and (max-width: 1600px) {
	.width66 {
		width: 58%;
	}

	.width33 {
		width: 41%;
	}
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                button                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.btn-orange {
	border: none;
	box-shadow: none;
	min-width: 200px;
	height: 40px;
	font-size: 17px;
	background: #eb871e;
	color: #fff;
	border-radius: 20px;
	margin-bottom: 15px;

}
.btn-orange-absol {
	position: absolute;
	top: 10px;
	left: 10px;
	border: none;
	box-shadow: none;
	min-width: 110px;
	height: 35px;
	font-size: 17px;
	background: #eb871e;
	color: #fff;
	border-radius: 20px;
	margin-bottom: 15px;
	z-index: 99;
}

.btn-orange-absol>i{
	display: none;
}

.btn-blue {
	border: none;
	/* box-shadow: none; */
	min-width: 200px;
	height: 40px;
	font-size: 17px;
	background: #0976a9;
	color: #fff;
	border-radius: 20px;
	margin-bottom: 15px;
}

@media screen and (max-width: 1600px) {
	.btn-blue,
	.btn-orange {
		min-width: 150px;
		height: auto;
		font-size: 1rem;
		padding: 8px 25px;
	}
	.btn-orange-absol {
		min-width: 100px;
		height: auto;
		font-size: 1rem;
		padding: 5px 25px;
	}
}

.btn-blue:hover,
.btn-orange:hover,
.btn-orange-absol:hover {
	opacity: 0.7;
	box-shadow: -3px 2px 3px #1d1d1d52;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                footer                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.-footer-pos {
	display: flex;
	bottom: 0;
	margin-top: 50px;
	width: 100%;
	justify-content: center;
	background-color: #299fd6;
}

.-footer {
	/* border-top: 1px solid #eee; */
	display: flex;
	justify-content: space-between;
	padding: 20px;
	width: 1100px;
}

.-footer-contact > div {
	display: flex;
	width: 300px;
	align-items: flex-end;
	text-align: left;
	margin-bottom: 15px;
}

@media screen and (min-width: 1600px) {
	.-footer {
		width: 1400px;
	}
}

.-footer-contact > div p {
	margin: 0;
	padding-left: 10px;
	font-size: 1rem;
	color: #fff; /* change from #bbb */
}

.-footer-contact > div > a {
	font-size: 1rem;
	color: #299fd6;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                Navbar                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.NavHeader {
	justify-content: center;
	display: grid;
	background-image: url(../icons/Component\ 1\ –\ 18.png); /* The image used */
	background-size: contain; /* Resize the background image to cover the entire container */
	background-position: bottom; /* Bottom the image */
	background-repeat: no-repeat; /* Do not repeat the image */
}
.NavHeader-notlogin {
	padding-top: 20px;
	justify-content: center;
	display: grid;
	background-image: url(../icons/Component\ 1\ –\ 18.png); /* The image used */
	background-size: contain; /* Resize the background image to cover the entire container */
	background-position: bottom; /* Bottom the image */
	background-repeat: no-repeat; /* Do not repeat the image */
}

.NavHeader-notlogin > .nav-head-cen {
	justify-content: center;
}
.NavHeader-notlogin > .nav-head {
	align-items: flex-end;
}

@media screen and (min-width: 1600px) {
	.NavHeader {
		background-size: cover; /* Resize the background image to cover the entire container */
		background-position-y: -18px; /* Bottom the image */
		background-repeat: no-repeat; /* Do not repeat the image */
	}

	.NavHeader-notlogin {
		background-size: cover; /* Resize the background image to cover the entire container */
		background-position: bottom; /* Bottom the image */
		background-position-y: -15px;
	}
}

.nav-top {
	display: flex;
	margin: 0;
	padding-top: 5px;
	padding-bottom: 0px; /* change from padding-bottom: 5px; */
	/* border-top: 1px solid #eee; */
	/* border-bottom: 1px solid #eee; */
	/* width: 1206px; */
	/* height: 40px; */
}

.navbar-list {
	display: inline-flex;
	align-items: center;
	/* margin: 5px 0;  */ /* change to padding */
	padding: 10px 0;
	justify-content: center;
	/* border-right: 1px solid #eee; */
	/* width: 201px; */
}

.navbar-list a:focus {
	outline: none;
	text-decoration: none;
}

.navbar-list img {
	margin-right: 10px;
}

/* .navbar-list.-active>a {
    color: #299fd6;
} */
/* .navbar-list.-active>a>p {
    color: #ff3300;
} */
/* change to navbar-list.-active*/
.navbar-list.-active {
	border-bottom: 5px solid #f08c1e;
	padding: 0 10px !important;
}

.navbar-list p {
	margin: 0;
	font-size: 1rem;
	display: inline;
	color: #fff; /* new color */
}

.navbar-list a {
	/* color: #333; */
	color: #fff; /* new color */
}

.navbar-list a:hover {
	text-decoration: none;
	color: #eb871e;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           Navbar head                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.nav-head {
	margin: 2px 0px;
	height: 60px;
	display: inline-flex;
	width: 1206px;
	margin: 0;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.nav-head-left {
	display: inline-flex;
	align-items: center;
}

.nav-head-left p {
	margin: 0;
}

.nav-head-left-img {
	height: 40px;
	width: 40px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 10px;
	border: 1px solid #777;
}

.nav-head-left-img img {
	height: 100%;
	/* margin-left: -15px; */
}

.nav-head-cen {
	display: inline-flex;
	align-items: center;
	height: 40px;
}

.nav-head-cen img {
	height: 100%;
}

.nav-head-right {
	display: inline-flex;
	align-items: center;
}

.nav-head-right-radius {
	cursor: pointer;
}

.nav-head-right-radius p {
	margin: 0;
}

.nav-head-right-radius .noti-alert {
	background: red;
	border-radius: 100%;
	height: 20px;
	width: 20px;
	color: white;
	margin-left: 10px;
	margin-top: -5px;
	position: absolute;
}

.nav-head-right-radius .noti-alert p {
	line-height: 21px;
	font-size: 1rem;
	color: #fff;
	text-align: center;
}

.-radius {
	background: red;
	color: white;
	border-radius: 20px;
	height: 26px;
	align-items: center;
	display: flex;
	justify-content: center;
}

.nav-head-right-out {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	margin-left: 10px;
}

.nav-head-right-out img {
	margin-right: 8px;
}

.nav-head-right-out p {
	margin: 0;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           Navbar left                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.nav-left-top {
	/* display: grid;
    justify-content: center; */
	margin-bottom: 20px;
}

.nav-left-close {
	display: flex;
	justify-content: flex-end;
	margin-top: -15px;
}

.nav-left-close button {
	background: #fff;
	box-shadow: none;
	border: none;
	font-size: 22px;
}

.nav-left {
	background: #fff;
	padding: 15px 0;
	width: 220px;
	box-shadow: 3px 2px 8px #2f2f2f47;
	z-index: 9;
	display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
	bottom: 0;
	transition: position 10s;
}

/* .nav-left-label {} */

.nav-left-label ul {
	padding: 0;
	text-align: left;
}

.nav-left-label ul > li {
	list-style: none;
	/* height: 40px; */
	align-items: center;
	display: grid;
	padding: 5px 15px;
}

.nav-left-label ul > li > a {
	color: #333;
}

.nav-left-label ul > li:hover {
	background: #299fd6;
}
.nav-left-label ul > li.active {
	background: #299fd6;
}
.nav-left-label ul > li.active a {
	color: #ffffff !important;
}

.nav-left-label ul > li:hover a {
	text-decoration: none;
	color: white;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           Navbar tabs                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.nav-tabs > li {
	width: 33.33%;
}

.nav-tab ul {
	display: flex;
	justify-content: space-between;
	/* justify-content: center; */
	margin: 10px 0;
	border-top: 1px solid #ddd;
	width: 1206px;
}

.nav-tab > ul > li > a {
	color: #333;
	border-radius: 0;
}

.nav-tab > ul > li.active > a {
	color: #2196f3;
	cursor: default;
	background-color: #fff;
	border: 1px solid #ddd;
	border-top: 0;
	border-bottom: 0;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           Navbar report                                                              */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.nav-report {
	display: flex;
	justify-content: center;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           Navbar head Admin                                                        */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.setCenter.-admin {
	/* display: inline; */
	background-color: #eaeaea;
	min-height: 100vh;
	display: flex;
}

.setCenter.-admin .panel-right {
	width: calc(100% - 280px);
	margin-left: 280px;
	background: #f6f9fb;
	/* height: 100vh; */
	/* height: calc(100vh + 48px); */
}

/* .panel-content {
    padding: 10px;
} */

.nav-left-top.-admin {
	display: block;
	margin: 10px;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
}

.nav-left.-admin {
	width: 280px;
	padding-bottom: 0;
	position: fixed;
	/* height: fit-content; */
	/* height: max-content; */
	height: 100vh;
	background-image: url('../icons/drawer-pic-tran.png');
	background-position: bottom; /* set props background-image */
	background-repeat: no-repeat; /* set props background-image */
	background-size: contain; /* set props background-image */
}

.nav-head-admin {
	background: #29a4db;
	box-shadow: 0 5px 8px #3333;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 15px;
}

.nav-head-admin .-left {
	font-size: 18px;
	color: #fff;
	font-weight: 500;
}

.nav-head-admin .-right .-noti {
	width: 20px;
}

.nav-head-admin .-right .-noti img {
	width: 100%;
}

.nav-head-admin .-right {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav-head-admin .-right .-logout {
	border: 1px solid #5dc0ec;
	background: #5dc0ec;
	height: 40px;
	padding: 0 30px;
	border-radius: 20px;
	color: #fff;
	transition: all .4s;
	font-size: 1rem;
	line-height: 37px;
	cursor: pointer;
	margin-left: 15px;
}

.nav-head-admin .-right .-logout i {
	display: none;
}

.nav-head-admin .-right .-logout:hover {
	background: #fff;
	color: #5dc0ec;
	transition: all .4s;
}

@media screen and (max-width: 1600px) {
	.nav-head-admin .-right .-logout {
		height: auto;
		padding: 0 20px;
		font-size: 1rem;
		line-height: 30px;
	}
}

.admin-logo-head {
	display: inline-flex;
	align-items: flex-start;
	margin-bottom: 20px;
}

.admin-logo-head img {
	width: 74px;
	margin-top: 6px;
}

.admin-logo-head .-right {
	text-align: left;
	margin-left: 10px;
}

.admin-logo-head .-right h2 {
	color: #29a4db;
	font-size: 1.5rem;
	margin-top: 0;
	margin-bottom: 5px;
}

.admin-logo-head .-right h5 {
	color: #f08c1e;
	margin-top: 0;
}

.setFxCen {
	display: flex;
	justify-content: center;
}

.-cycle {
	border-radius: 100%;
	overflow: hidden;
	height: 120px;
	width: 120px;
	border: 1px solid #ccc;
}

.-cycle img {
	height: 100%;
	/* margin-left: -40px; */
	object-fit: cover;
	object-position: center;
	width: 100%;
}

.nav-left-top.-admin p {
	color: #29a4db;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 0;
	font-size: 1rem;
}

.nav-left-top.-admin label {
	color: #666;
	font-weight: 300;
	text-align: center;
	display: block;
	margin-bottom: 10px;
}

.nav-left-top.-admin button {
	border: 1px solid #29a4db;
	background: #fff;
	height: 40px;
	padding: 0 30px;
	border-radius: 20px;
	color: #29a4db;
	transition: all .6s;
}

.nav-left-top.-admin button:hover {
	transition: all .6s;
	border: 1px solid #29a4db;
	background: #29a4db;
	color: #fff;
}

.nav-left-top.-admin button:focus {
	outline: none;
}

/* .nav-left-label.-admin {} */

.nav-left-label.-admin p {
	margin-left: 10px;
	color: #29a4db;
	font-size: 1.3rem;
}

.nav-left-label.-admin .imgBt {
	width: 100%;
	/* position: absolute; */
	left: 0;
	bottom: 0;
}

.nav-left-label.-admin ul > li > a {
	font-size: 1rem;
	color: #333;
	font-weight: 400;
}

.nav-left-label.-admin ul > li:hover a {
	color: #fff;
}

/* ===== responsive navbar ============================================================================================================================= */
@media screen and (max-width: 1600px) {
	.setCenter.-admin .panel-right {
		width: calc(100% - 220px);
		margin-left: 220px;
	}
	.nav-left-top.-admin {
		margin: 0px;
		padding-bottom: 10px;
	}

	.nav-left.-admin {
		width: 220px;
		/* background-position: center 82vh; */
	}
	.admin-logo-head {
		margin-bottom: 10px;
	}
	.admin-logo-head img {
		width: 60px;
		margin-top: 6px;
	}
	.admin-logo-head .-right h2 {
		font-size: 1.3rem;
	}
	.admin-logo-head .-right h5 {
		font-size: 1.2rem;
	}

	.-cycle {
		height: 80px;
		width: 80px;
	}
	.-cycle img {
		margin-left: 0px;
	}
	.nav-left-top.-admin p {
		margin-top: 10px;
		margin-bottom: 0;
		font-size: 1.2rem;
	}
	.nav-left-top.-admin label {
		font-size: 1rem;
	}
	.nav-left-top.-admin button {
		height: 30px;
		font-size: 1rem;
	}
	.nav-left-label ul > li {
		/* height: 30px; */
	}
	.nav-left-label.-admin ul > li > a {
		font-size: 1rem;
	}
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                            pagination                                             */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.react-bootstrap-table-page-btns-ul.pagination {
	margin: 0;
}

.react-bootstrap-table-page-btns-ul.pagination li a {
	background-color: transparent;
	border-color: #299fd6;
}

.page-item.active .page-link {
	background-color: #299fd6;
	border-color: #299fd6;
}

.btn.btn-default.btn-secondary.dropdown-toggle {
	background-image: none;
	background-color: #299fd6;
	color: #fff;
	height: 28px;
	text-shadow: none;
	border: none;
}

.btn.btn-default.btn-secondary.dropdown-toggle:hover {
	background-color: #337ab7;
	border-color: #337ab7;
}

.dropdown-toggle::after {
	display: none;
}

.pagination-next-page,
.pagination-prev-page {
	display: flex;
	font-size: 3rem;
	height: 100%;
	color: #299fd6;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	margin: auto .5rem;
}

.pagination-next-page:hover,
.pagination-prev-page:hover {
	color: #337ab7;
}

/* ========================================================================================================================================= */
.text-align-right {
	text-align: right;
}
.text-align-left {
	text-align: left;
	white-space: initial;
    text-overflow: ellipsis;
    overflow: hidden;
}



.react-bs-container-header {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.react-bs-table .table-bordered > tbody > tr > td {
	padding: 5px !important;
}

.react-bs-table .table-bordered > tbody > tr:nth-child(2n) {
	background-color: #f6f9fb !important;
}
.react-bs-table .table-bordered > tbody > tr:hover {
	background-color: #eee !important;
}

/* ======= override bootstrap table ========================================================================================================================== */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	vertical-align: middle;
}

/* ========================================================================================================================================= */
.w-10 {
	width: 10% !important;
}
.w-20 {
	width: 20% !important;
}
.w-30 {
	width: 30% !important;
}
.w-40 {
	width: 40% !important;
}
.w-60 {
	width: 60% !important;
}

/* ======= custom bootstrap modal ========================================================================================================================== */

.modal-hotline {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}
.modal-bg-hotline {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	display: block;
	width: 100%;
	height: 100%;
	outline: 0;
	background-color: #00000098;
}
.modal-dialog-hotline {
	position: relative;
	width: auto;
	height: 100vh;
	margin: .5rem;
	pointer-events: none;
	-webkit-transform: none;
	transform: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-content-hotline {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 500px;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: .3rem;
	outline: 0;
	z-index: 99999;
}

.modal-header-hotline {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 1rem;
	border-top-left-radius: .3rem;
	border-top-right-radius: .3rem;
	text-align: center;
}

.modal-title-hotline {
	margin: 0;
	line-height: 1.42857143;
}

.close-hotline {
	position: absolute;
	top: 0px;
	right: 5px;
	border: 0;
	font-size: 18px;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.modal-body-hotline {
	position: relative;
	padding: 1rem;
	display: grid;
}

.modal-body-hotline > div {
	display: flex;
	flex-wrap: nowrap;
}
.modal-body-hotline > div > .img {
	width: 130px;
	height: 130px;
	border: 1px solid #ddd;
	padding: 10px;
}
.modal-body-hotline > div > div > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}
.modal-body-hotline > div > div {
	margin: 0 10px;
}
.modal-body-hotline > div > .fr {
	width: 300px;
}
.modal-body-hotline > div > .LabelInput-panel {
	width: 45%;
}
.modal-body-hotline > div > div > .LabelInput-panel {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.modal-body-hotline > div > div > .LabelInput-panel > input,
.modal-body-hotline > div > .LabelInput-panel > input {
	font-size: 1rem;
	margin-bottom: 1rem;
	color: #000;
	box-shadow: none;
	padding-top: 15px;
	padding-bottom: 15px;
	font-weight: 200;
	display: block;
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	padding: .375rem .75rem;
	line-height: 1.5;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.modal-footer-hotline {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	border-bottom-right-radius: .3rem;
	border-bottom-left-radius: .3rem;
}
.modal-button {
	min-width: 100px;
	text-align: center;
	background-color: #0eaae7;
	border-radius: 20px;
	color: #ffffff;
	font-size: 1rem;
	opacity: 1;
	border: unset !important;
}
.modal-button:hover {
	opacity: .9;
	color: #fff;
}

.bg-danger {
	background-color: #ff1100 !important;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           select form group                                                        */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.select-arrow {
	position: relative;
	text-align: left;
}
.select-arrow > select {
	padding: 0 5px !important;
	/* -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none; */
}
/* .select-arrow:after {
    content: '';
    position: absolute;
    bottom: .75rem;
    right: .75rem;
    border: 6px solid transparent;
    border-color: #0582f7 transparent transparent transparent;
    transition: all .8s;
}  */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                           override sweet alert 2                                                        */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.swal2-popup {
	width: 478px !important;
}
.swal2-icon {
	width: 80px !important;
	height: 80px !important;
	margin: 20px auto !important;
}
.swal2-icon::before {
	font-size: 6rem !important;
}

.swal2-title {
	color: rgba(0, 0, 0, .65);
	font-weight: 600 !important;
	text-transform: none;
	position: relative;
	display: block;
	padding: 13px 16px !important;
	font-size: 1rem !important;
	line-height: normal;
	text-align: center;
	margin-bottom: 0;
}

.swal2-actions {
	padding-top: 13px !important;
	margin-top: 13px !important;
	padding: 13px 16px !important;
}

.swal2-styled.swal2-confirm {
	background-color: #7cd1f9 !important;
}
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
	border-radius: 5px !important;
	font-size: 1rem !important;
	font-weight: 500;
	padding: 10px 24px;
}

.swal2-content {
	font-size: 1rem !important;
}

/* --- Calendar --------------------------------------------------------------------------------------------------------------------------------- */
.react-datepicker-popper {
	z-index: 9 !important;
}

/* ------------------------------- */
.fgenzxc {
	background: transparent;
	border: unset;
	border-bottom: 2px solid #29a4db;
	border-radius: unset !important;
}
.fgenzxc-save {
	background: transparent;
	border: unset;
	border-bottom: 2px solid red;
	border-radius: unset !important;
}
.fgenzxc.form-control:disabled {
	background-color: transparent;
	/* color: #777; */
}

.sdfrzxc {
	background: transparent;
	border: unset;
	border-bottom: 2px solid #29a4db;
	color: #29a4db;
}
.sdfrzxc-save {
	background: transparent;
	border: unset;
	border-bottom: 2px solid red;
	color: #29a4db;
}

.xzejgho {
	width: 900px;
	height: 40px;
	background-color: #5dc0ec;
	border: unset;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	color: #fff;
	font-size: 1.5rem;
}
.xzejgho i {
	font-size: 1.8rem;
}

/* ----------------------------------------------------------------------------------------------------- */

.download-app{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ffffff;
}

.download-app>.ios, .download-app>.andriond{
    color: #ffffff;
    text-decoration: none;
}
.download-app strong{
    font-weight: 500;
    font-size: 1.5rem;
}
.download-app>.ios>div:nth-of-type(2), .download-app>.andriond>div:nth-of-type(2){
    display: grid;
    margin: 0 10px;
}

.download-app>.ios i{
    font-size: 4rem
}
.download-app>.andriond i{
    font-size: 3.5rem
}
.download-app>.ios, .download-app>.andriond{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 50px auto 50px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 10px;
    margin: 10px;
    padding: 5px;
}
.download-app>.ios img, .download-app>.andriond img{
    width: 100%;
}

@media screen and (max-width: 769px){
	.download-app{
		display: none;
	}
}

.policy{
	position: absolute;
	left: 20px;
	bottom: 5px;
	display: inline;
}
.policy a{
	color: #ffffff;
	margin-left: 1rem;
}

@media screen and (max-width:578px){
	.policy{
		position: relative;
	}
}

.vh-100{
    height: 100vh !important;
}

