html{
	font-size: 14px !important;
}
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1600px) {
	body {
		/* font-size: small !important; */
		font-weight: 300 !important;
	}
}
/* @media screen and (min-width: 1600px) {
	html{
		font-size: 15px;
	}
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
	color: #5e5e5e;
}

/* ---- override ------------------------------------------------------------------------------------------------------ */
label {
	font-weight: 400 !important;
}
/* ---- override ------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 1600px) {
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		padding-right: 5px !important;
		padding-left: 5px !important;
	}
}
.Countdown {
	/* margin: 10px auto; */
	/* padding-bottom: 20px; */
	display: flex;
}

.Countdown-col {
	display: inline-block;
}

.Countdown-col-element {
	display: inline-block;
	margin: 0 2.5px;
	display: flex;
	flex-direction: column;
}

.Countdown-col-element strong {
	/* font-size: 15px; */
}
