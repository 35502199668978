@media screen and (min-width: 1281px){
   
 
}
@media screen and (max-width: 1280px){
  

}
@media screen and (max-width: 1024px){
    
    .government-detail-content > .detail > div:last-of-type > div:first-of-type {
        margin-left: 5px;
        position: inherit;
        flex:auto;
        flex-wrap: wrap;
        width: auto !important;
      
    
       
    }
   
   
   
}
@media screen and (max-width: 901px){
    
    .report-chart > .chart-note  {
    
        display: grid;
        align-items: center;
        margin-left: 10px;
        width: 100%;
        grid-template-columns: auto;
     }
    
     .recharts-surface{
        width: 290px;
        
        

     }
     .admin-hotline>div:first-child {
        padding-top: unset;
    }
   
    
  
}

@media screen and (max-width: 579px){
    .report-header {
        flex-direction: unset;
        font-size: small;
       
    }
  
    .recharts-layer .recharts-label-list{
        width: 200px; 
        height: 400px; 
        position: absolute;
         -webkit-tap-highlight-color: transparent; 
         user-select: none; 
         cursor: default;
    }
  
    .chart-x {
        
        right: 10px;
       
  
    }
    .recharts-wrapper{
      
        width: 100px;
        height: 240px;
    }
    
    
    .styles_modal__gNwvD {
        max-width: 300px;
        position: relative;
        padding: 0.599rem;
        background: #ffffff;
        background-clip: padding-box;
        box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
        margin: auto;}
    /* .d-flex {
        display: inherit  !important;
    } */
    .nav-head-admin .-left{
        font-size: 14px;
    }
    .react-bs-table table {
        margin-bottom: 0 !important;
        table-layout: inherit !important;

    }

  .ggghh{
    width: auto;
  }
  
}
@media screen and (max-width: 476px){
   
   
}
@media screen and (max-width: 321px){
  
    
}