.-q-accordion {
    position: relative;
    background-color: transparent;
    margin: 10px;
}

.-q-detail{
    padding-left: 40px;
    font-weight: 300;
    text-indent: 30px;
    word-break: break-word;
}

.-q-title.colorBlue {
    border-top: none;
    border-bottom: 1px solid #29A4DB;
    border-radius: 0;
}

.-q-title.colorBlue span {
    color: #29A4DB;
}

.-q-title {
    width: 50%; /* add by khawkriab */
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
    line-height: 2;
    font-weight: 300;
    position: relative;
    padding: 10px;
    z-index: 2000;
    margin-top: 2px;
    transition: all .2s ease-in;
    border-bottom: 1px solid #ddd;
}

.-q-title-text {
    font-size: 1rem;
    font-weight: 500;
}

.-q-title:hover {
    cursor: pointer;
}

.-q-title:active {
    color: #299fd6;
}

.-q-title img {
    margin-right: 10px;
}

.-q-content {
    position: absolute; /* add by khawkriab */
    left: calc(50% + 15px); /* add by khawkriab */
    top: 0px; /* add by khawkriab */
    height: 30px;
    background-color: transparent;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    text-align: center;
    z-index: 1000;
    margin-top: -30px;
    margin-left: 25px;
    text-align: left;
    transition: all 200ms cubic-bezier(0.39, 0.58, 0.57, 1);
    /* transition: all 200ms cubic-bezier(0.600, -0.280, 0.735, 0.045); */
}

.-q-content-open {
    margin-top: 0px;
    height: fit-content;
    transition: all 350ms cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.-q-content-text {
    padding: 15px;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    transition: all .2s cubic-bezier(0, 0, 0.68, 0.83);
    /* transition: all .2s ease-in; */
}

.-q-content-text-open {
    color: #000;
    padding-top: 10px;
    font-size: 1rem;
    font-weight: 300;
    visibility: visible;
    opacity: 1;
    transition: all .8s ease-in;
}

.fa-angle-down {
    font-size: 1rem;
    color: #299fd6;
    transition: all .6s cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.fa-angle-right {
    font-size: 1rem;
    color: #299fd6;
    transition: all .6s cubic-bezier(0.080, 1.090, 0.320, 1.275);
}

.fa-rotate-180 {
    color: #299fd6;
}

.arrow-wrapper {
    position: absolute;
    right: 0;
}

/* ============================================================================= */