.admin-forW-panel {
  display: flex;
}

.admin-forW-left {
  margin-right: 10px;
}

.admin-forW-left h1 {
  font-size: 1rem;
  margin-top: 10px;
  padding-bottom: 6px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-forW-left-group {
  width: 200px;
}

.admin-forW-left-dep {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  cursor: pointer;
}

.admin-forW-left-dep:hover {
  background: #3333330a;
}

.admin-forW-left-dep p {
  margin: 0;
}

.admin-forW-rad {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
}

.admin-forW-rad img {
  height: 100%;
  margin-left: -15px;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          admin-hotline                                                             */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.admin-hotline {
  margin-inline-start: unset !important;
  padding-inline-start: unset !important;
  position: relative;
}
.admin-hotline > div {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px;
  align-items: center;
}
.admin-hotline > div:first-child {
  padding-top: unset;
}
.admin-hotline > div:last-child {
  border-bottom: unset;
  padding: 10px;
}
.admin-hotline > div > .admin-hotline-edit {
  position: absolute;
  right: 0px;
}
.admin-hotline > div > div > i {
  text-align: end;
  cursor: pointer;
  color: #29a4db;
  width: 40%;
  font-size: 1rem;
  font-style: normal;
}
.admin-hotline > div > div > i:hover {
  color: #106b96;
  text-decoration: #106b96 underline;
}

.admin-hotline-center {
  display: flex;
  justify-content: center;
  margin: 10px 0 50px 0;
}

.admin-hotline-center .row {
  width: 1100px;
}

.admin-hotline-fx {
  position: relative;
  display: grid;
  width: 100%;
  margin: 20px 0;
  grid-template-columns: 50px auto;
}
.admin-hotline-fx:after {
  position: absolute;
  content: "";
  width: 90%;
  bottom: -20px;
  left: 0px;
  border-bottom: 1px solid #ccc;
}

.admin-hotline-rad {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.admin-hotline-rad img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
  /* margin-left: -15px; */
}

.admin-hotline-text {
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  width: calc(100% - 85px);
}
.admin-hotline-text span {
  width: 250px;
  /* border-right: 1px solid #eee; */
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
}
.admin-hotline-text span:first-of-type {
  width: 220px;
}
.admin-hotline-text span:last-of-type {
  width: 350px;
}
.admin-hotline-text span i,
.admin-hotline-text span a {
  padding-left: 10px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  color: #777;
}
.admin-hotline-text span a {
  text-decoration: underline !important;
  color: #29a4db;
}
.admin-hotline-text > span:first-of-type {
  padding-left: unset;
}
.admin-hotline-text > span:last-of-type {
  border-right: unset;
}

.admin-hotline-text h1 {
  width: 100%;
  margin: 0 0 5px 0;
  font-size: 1rem;
  text-align: left;
  color: #29a4db;
}

.admin-hotline-text p,
.admin-hotline-text a {
  font-size: 1rem;
  margin: 0 0 5px 0;
  font-weight: 300;
  color: #333;
  min-width: 280px;
}

.admin-hotline-text a:hover {
  color: #337ab7;
  text-decoration-line: none;
}

.admin-hotline-text p > img,
.admin-hotline-text a > img {
  margin-right: 10px;
}

.admin-hotline-list {
  display: grid;
  grid-template-columns: 50% 50%;
}
/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          admin-question                                                             */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.admin-question {
  margin-inline-start: unset !important;
  padding-inline-start: unset !important;
  position: relative;
}
.admin-question > div {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
}

.admin-question > div:last-child {
  border-bottom: unset;
  padding: 10px;
}

.admin-question > div > .admin-question-edit {
  position: absolute;
  right: 0px;
}
.admin-question > div > div > i {
  text-align: end;
  cursor: pointer;
  color: #29a4db;
  width: 40%;
  font-size: 1rem;
  font-style: normal;
}
.admin-question > div > div > i:hover {
  color: #106b96;
  text-decoration: #106b96 underline;
}

.admin-question-text {
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 5px;
  width: calc(100% - 30px);
}

.admin-question-text h1 {
  width: 100%;
  font-size: 1rem;
  text-align: left;
  color: #000;
  margin-left: 15px;
  font-weight: 400;
}
.admin-question-text a:hover {
  color: #337ab7;
  text-decoration-line: none;
}

.admin-question-text p > img,
.admin-question-text a > img {
  margin-right: 10px;
}

/* ============================================ */

.admin-news-fx {
  display: flex;
  margin: 20px 0 0 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  /* width: 500px; */
  height: 180px;
  cursor: pointer;
}
.admin-news-fx:hover {
  border-bottom: 1px solid #29a4db;
}

.admin-news-rad {
  width: 200px;
  height: 100%;
  /* overflow: hidden; */
  margin-right: 10px;
}
.admin-news-rad > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.admin-news-text {
  width: 80%;
  height: 100%;
  text-align: left;
  position: relative;
  overflow: hidden;
}
.admin-news-text .-ellipsis:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 16px;
  bottom: 0px;
  right: 0px;
  background-image: linear-gradient(180deg, transparent, #fff 100%);
}

.admin-news-text h1 {
  margin: 0 0 5px 0;
  font-size: 1rem;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.admin-news-text p {
  margin: 0 0 5px 0;
  font-weight: 300;
  font-size: 1rem;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                                                                                                    */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.admin-PanelHead {
  margin-top: -120px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.admin-PanelWhi {
  background: #fff;
  border-radius: 6px;
  margin-top: 20px;
  padding: 20px;
  position: relative;
}

.admin-PanelWhi.marg {
  margin-top: 10px;
}

.admin-PanelWhi.margL {
  margin-top: 10px;
  margin-left: 10px;
}

.admin-PanelWhi.history {
  margin-top: 10px;
  margin-left: 10px;
  min-width: 350px;
}

.admin-PanelWhi.history .styleScroll {
  max-height: 720px;
  text-align: left;
  overflow-y: auto;
}

.admin-PanelWhi.history .styleScroll .historyBlock {
  border-bottom: 1px solid #f6f9fb;
}

.admin-PanelContent {
  padding: 20px;
}

.bgDrop {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.dropdownNoti {
  max-width: 350px;
  background: #fff;
  box-shadow: 2px 2px 8px #3333;
  border: 1px solid #ccc;
  position: absolute;
  top: 40px;
  right: 165px;
  border-radius: 8px;
  z-index: 9999;
  overflow: hidden;
  animation: fadeNoti 0.2s linear;
  max-height: 550px;
  overflow-y: auto;
}

.dropdownNoti h4 {
  font-size: 1.1rem;
}

.dropdownNoti p {
  font-size: 0.889rem;
}

.layerNoti {
  padding: 10px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.layerNotNoti {
  padding: 10px;
}

.layerNotNoti p {
  margin: 0;
  text-align: center;
}

.layerNoti:hover,
.layerNoti.even:hover {
  background: #f2f2f2;
}

.layerNoti h6 {
  margin: 0;
  color: #29a4db;
}

.layerNoti span {
  margin: 0;
  color: #aaa;
  font-size: 0.899rem;
}

.layerNoti label {
  margin: 0;
  color: #29a4db;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 5px;
}

.layerNoti p {
  margin: 0;
  color: #5e5e5e;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: pre;
}

.layerNoti.even {
  background: #edf2fa;
}

@keyframes fadeNoti {
  0% {
    transform: scale(0);
    transform-origin: top right;
  }
  100% {
    transform: scale(1);
  }
}

/* ======================================================================================== */

#tracking,
#update {
  color: #29a4db;
  font-weight: 300;
}

#update {
  cursor: pointer;
}

#update:hover {
  text-decoration: underline;
}

.ChangeStatus {
  border-radius: 8px;
}

.ChangeStatus .layout {
  padding: 40px;
  text-align: center;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          Adminreport                                                       */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

#my-tableHeader-class-text-left > div > div > div > table > thead > tr > th:nth-child(2) {
  text-align: left !important;
}
/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          Admin-Edit-News                                                       */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.custom-file-upload input[type="file"] {
  display: none;
}
.custom-file-upload {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 10px;
  background-color: #fff;
  padding: 6px 12px 6px 36px;
  cursor: pointer;
  border-radius: 2rem;
  z-index: 99;
}
.custom-file-upload:before,
.custom-file-upload-2:before,
.second-display-list > .list:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0px;
  width: 20px;
  height: 100%;
  background-image: url("../icons/Asset51.png");
  background-repeat: no-repeat;
  background-position: center;
}
.custom-file-upload-2 input[type="file"] {
  display: none;
}
.custom-file-upload-2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  padding: 0 0 0 36px;
}
.second-display {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.second-display-list {
  width: 100%;
  max-height: 190px;
  overflow-y: auto;
}

.second-display-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.second-display-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.second-display-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.second-display-list > .list {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px 5px 5px 35px;
  overflow-x: hidden;
}
.second-display-list > .list > div {
  width: 98%;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  text-align: left;
}
.second-display-list > .list .close {
  position: absolute;
  right: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: unset;
  width: unset !important;
}

.core-img {
  position: relative;
  display: block;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  background-image: url("../icons/Asset31.png");
  background-position: center;
  background-repeat: no-repeat;
}
.core-img > div {
  display: block;
  width: 100%;
  height: 40vh;
}
.core-img > div > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* ------ override ---------------------------------------------------------------------------------------------------------------------- */
.rdw-editor-wrapper {
  border: 1px solid #ddd;
}
.public-DraftEditor-content {
  min-height: 300px;
  padding: 0 5px;
  font-size: 1rem;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.rdw-option-wrapper {
  border: unset !important;
}
.rdw-option-active {
  background: #ddd !important;
}

/* ------ /override ---------------------------------------------------------------------------------------------------------------------- */
.news-carousel {
  width: 800px;
  display: flex;
}
.news-carousel > div:first-of-type {
  position: relative;
  margin: 0px;
  padding: 0px;
  height: 324px;
  width: 624px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.news-carousel > div:first-of-type > div {
  position: absolute;
  display: flex;
  overflow: hidden;
  transition: left 1s;
}
.news-carousel > div:first-of-type > div > img {
  float: left;
  height: 324px;
  width: 624px;
  object-fit: contain;
  object-position: center;
}
.news-carousel > div:last-of-type {
  width: 100px;
  max-height: 324px;
  overflow-x: hidden;
  padding-right: 5px;
}

.news-carousel > div:last-of-type img {
  width: 100%;
  margin: 2px;
  cursor: pointer;
  padding: 2px;
  margin-right: 20px;
}
.news-carousel > div:last-of-type img:hover {
  border: 2px solid #29a4db;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          admin contact                                                       */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.contact-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-input > div:first-child {
  width: 100%;
}
.contact-input > div {
  margin: 1rem;
  width: 45%;
  text-align: left;
}
.contact-input > div:last-child {
  margin: 1rem;
  width: 140px;
  text-align: center;
  margin: auto;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          admin admin-central                                                */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */

.admin-central {
  margin-inline-start: unset !important;
  padding-inline-start: unset !important;
  position: relative;
}
.admin-central > div {
  display: flex;
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 10px;
  align-items: center;
}

.admin-central > div:first-child {
  padding-top: unset;
}
.admin-central > div:last-child {
  border-bottom: unset;
  padding: 10px;
}
.admin-central-edit {
  display: grid;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: transparent;
  border: unset !important;
}
.admin-central-edit > i {
  text-align: end;
  cursor: pointer;
  color: #29a4db;
  font-size: 1rem;
  font-weight: 900;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 2px;
}
.admin-central-edit > i:hover {
  color: #106b96;
}
.admin-central-edit > .shows {
  display: none;
}
.admin-central-edit:focus > .shows {
  display: flex !important;
}
.admin-central-edit > .shows {
  flex-direction: column;
  width: 100px;
  height: auto;
  color: #000;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 99;
}
.admin-central-edit > .shows > span {
  padding: 5px 0 5px 10px;
}
.admin-central-edit > .shows > span:hover {
  background-color: #eee;
}

.admin-central-img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.admin-central-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

.admin-central-text {
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  /* width: 840px; */
  cursor: pointer;
}
.admin-central-text span {
  width: fit-content;
  border-right: 1px solid #eee;
  padding-left: 25px;
  display: inline-flex;
  align-items: center;
}
.admin-central-text span:first-of-type {
  width: 220px;
}
.admin-central-text span:last-of-type {
  width: 350px;
}
.admin-central-text span i {
  padding-left: 0px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  color: #777;
}
.admin-central-text > span:first-of-type {
  padding-left: unset;
}
.admin-central-text > span:last-of-type {
  border-right: unset;
}
.admin-central-text > span > strong {
  border-right: unset;
}
.admin-central-text > span > strong {
  padding-left: 5px;
  color: #29a4db;
  font-weight: 400;
}

.admin-central-text h1 {
  width: calc(100% - 20px);
  margin: 0 0 5px 0;
  font-size: 1rem;
  text-align: left;
  color: #29a4db;
}
.admin-central-text h1:hover {
  text-decoration: underline;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          admin central government detail                                                 */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.government-detail-content {
  display: flex;
  align-items: flex-start;
  height: 150px;
  margin-top: -75px;
}
.government-detail-content > .detail {
  display: grid;
  grid-template-rows: 50%;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  text-align: left;
}
.government-detail-content > .detail > div:first-of-type {
  display: flex;
  align-items: center;
}
.government-detail-content > .detail > div {
  font-size: 1rem;
}
.government-detail-content > .detail > div:last-of-type {
  display: flex;
}
.government-detail-content > .detail > div:last-of-type > div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 0 10px;
  border-radius: 5px;
  width: 33.339%;
  max-width: 450px;
  align-items: center;
}
.government-detail-content > .detail > div:last-of-type > div strong {
  color: #29a4db;
  padding: 0 10px;
}
.government-detail-content > .detail > div:last-of-type > div p {
  margin-bottom: unset !important;
}

.government-detail-content > .detail > div:last-of-type > div:first-of-type {
  margin-left: 0px;
}

.government-detail-content > .detail > div:first-of-type {
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
}
.government-detail-content > .detail > div:first-of-type > div {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.government-detail-content > .detail > div:first-of-type > div > a {
  line-height: unset;
}
.government-detail-content > .detail h2 {
  color: #fff;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.uydfero {
  border-bottom: 1px solid #29a4db;
}

.uydfero > div {
  border-bottom: 3px solid transparent;
}

.government-detail-menu {
  display: flex;
  margin-bottom: 1rem;
}
.government-detail-menu > div {
  display: flex;
  align-items: center;
  padding: 3px 30px;
  /* min-width: 150px; */
  font-size: 1rem;
  /* justify-content: center; */
  cursor: pointer;
}
.government-detail-menu > div:hover {
  border-bottom: 3px solid #29a4db;
  color: #29a4db;
}
.government-detail-menu > div:last-of-type:hover {
  border-bottom: unset;
  color: unset;
}

@media screen and (max-width: 1600px) {
  .government-detail-menu > div {
    padding: 3px 20px;
    font-size: 1rem;
  }
}
.government-detail-menu > div:last-of-type {
  display: inline-flex !important;
  margin-left: auto;
}
.government-detail-menu > div:last-of-type > div {
  width: 250px;
  margin-bottom: unset !important;
  margin-left: 1rem;
}
.government-detail-menu > .active {
  border-bottom: 3px solid #29a4db;
  color: #29a4db;
}
.government-detail-officer {
  padding-top: 2rem;
}

.government-detail-officer > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  padding: 1rem 0;
  font-size: 1rem;
}
.government-detail-officer > div > div {
  display: inline-flex;
  align-items: center;
}
.government-detail-officer > div > div > div > span {
  display: flex;
  min-width: 250px;
  max-width: 500px;
  align-items: center;
  padding-left: 1rem;
}
.government-detail-officer > div > div > div > span > strong {
  text-align: left;
  color: #29a4db;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.government-detail-officer > div > div img {
  width: 14px;
  height: 14px;
  margin-right: 1rem;
}

.government-detail-officer > div > div > strong {
  font-weight: 500;
  color: #29a4db;
  cursor: pointer;
}
.government-detail-officer > div > div > strong:hover {
  text-decoration: underline;
}

/* ================================================================================================================================== */

/* ================================================================================================================================== */

/*                                                                                                                                    */

/*                                                          admin depart and compaint                                                 */

/*                                                                                                                                    */

/* ================================================================================================================================== */

/* ================================================================================================================================== */
.depart-grid {
  display: grid;
  grid-template-columns: auto auto;
  width: 800px;
  justify-content: flex-start;
  text-align: left;
}

.depart-grid > div {
  display: flex;
  /* justify-content: ; */
}
.depart-grid > div > label {
  display: block;
  width: 130px;
  font-size: 1rem;
}
.depart-grid > div > span,
.depart-grid > div > a {
  display: block;
  width: 260px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.depart-grid > div > a {
  text-decoration: underline;
  cursor: pointer;
}

.depart-file {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  padding: 5px 5px 5px 35px;
  cursor: pointer;
  color: #000000 !important;
}
.depart-file.pdf {
  height: 25px !important;
}

.color-file-pdf {
  color: #000000 !important;
}
.delete-evidence {
  color: #29a4db
}
.hover-file:hover {
  text-decoration: underline !important;
}
.depart-file > div {
  /* width: 280px; */
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

@media screen and (max-width: 1600px) {
  .depart-file {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1601px) {
  .depart-grid {
    width: auto;
  }

  .depart-grid > div > span,
  .depart-grid > div > a {
    width: 450px !important;
  }
}

.pdf:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0px;
  width: 15px;
  height: 100%;
  background-image: url("../icons/Asset15.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.jpg:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0px;
  width: 20px;
  height: 100%;
  background-image: url("../icons/Asset51.png");
  background-repeat: no-repeat;
  background-position: center;
}

.depart-tracking > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.depart-tracking > div:last-of-type {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.depart-tracking > div > img {
  width: auto;
  height: 100px;
  margin: 5px;
  cursor: pointer;
}
.depart-tracking > div > img.full {
  width: 100% !important;
  height: auto !important;
}
.depart-tracking > div > img:hover {
  outline: 3px solid #31b9f8a4;
}
.depart-tracking > div > div:first-of-type {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.depart-tracking > div > div:first-of-type > div {
  color: #29a4db;
  font-size: 1rem;
  font-weight: 400;
}
.depart-tracking > div > div:first-of-type > span {
  font-size: 1rem;
  color: #555;
}
.depart-tracking > div > div:last-of-type > span {
  font-size: 1rem;
  color: #ff3300;
  margin-right: 10px;
  cursor: pointer;
}

.depart-about-file {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
}
.depart-about-file > a {
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  padding-left: 20px;
  margin: auto 3px;
}
.depart-about-file > a:hover {
  text-decoration: underline;
}
.depart-about-file .pdf:before {
  width: 10px;
  left: 5px !important;
}

.intensive {
  display: grid;
  grid-template-columns: auto calc(100% - 50px);
  align-items: flex-start;
  border-bottom: 1px solid #eee;
}
.intensive > div > div {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-size: 1rem;
  color: #555;
}
.intensive > div > div > strong {
  color: #29a4db;
}
@media screen and (min-width: 1600px) {
  .intensive > div > div > strong {
    font-size: 1rem;
    font-weight: 400;
  }
}

.intensive > div > div > span {
  font-size: 1rem;
}
.intensive > div > div:last-of-type {
  text-align: center;
}

/* -------------------------------------------------- */
.depart-chat {
  max-height: 270px;
}
@media screen and (min-width: 1600px) {
  .depart-chat {
    max-height: 500px;
  }
  .depart-chat > div > div {
    display: grid;
    grid-template-columns: 40px calc(100vw - 650px) !important;
  }
  .depart-chat > div > div > div > div > strong {
    font-weight: 400;
  }

  .chat-room {
    height: 730px !important;
  }
}

.depart-chat > div {
  display: flex;
  justify-content: space-between;
}
.depart-chat > div > div {
  display: grid;
  grid-template-columns: 40px calc(100% - 80px);
}
.depart-chat > div > text {
  font-size: 1rem;
  color: #555;
  margin-right: 1rem;
  padding: 5px 0px;
  white-space: nowrap;
}
.depart-chat > div > div > div {
  text-align: left;
  overflow-x: hidden;
}
.depart-chat > div > div > div:last-of-type {
  padding: 5px 0px;
}
.depart-chat > div > div > div > text {
  font-size: 1rem;
  color: #555;
}
.depart-chat > div > div > div > div > strong {
  color: #29a4db;
  font-size: 1.1rem;
}
.depart-chat > div > div > div > div > span {
  color: #777;
  font-size: 1.199rem;
  margin-left: 2rem;
}

/* ----------------------------------------------------- */
.depart-chat-people {
  display: flex;
  justify-content: space-between;
}
.depart-chat-people .add,
.add-wait {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: #aaa;
  font-size: 2rem;
  cursor: pointer;
}
.depart-chat-people .add:hover,
.add-wait:hover {
  color: #777;
}

.add-wait {
  font-size: 1rem;
}
.depart-chat-people > div > .waiting {
  display: flex;
  flex-direction: row;
}
.depart-chat-people > div > div > div {
  display: flex;
  align-content: center;
}
.depart-chat-people > div:last-of-type {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* --------------------------------- */
.chat-modal {
  display: flex;
  justify-content: space-between;
  width: 30rem;
  max-height: 300px;
}
.chat-modal > div {
  display: flex;
  align-items: center;
}
.chat-modal > div > div:last-of-type {
  display: flex;
  flex-direction: column;
}
.chat-modal > div > div:last-of-type > text {
  font-size: 1.3rem;
}
.chat-modal > div > div:last-of-type > text:first-of-type {
  color: #29a4db;
}
.chat-modal > div > div:last-of-type > text:last-of-type {
  color: #555;
  font-size: 1rem;
}
.chat-modal-del {
  color: #29a4db;
  cursor: pointer;
}
.chat-modal-del:hover {
  color: #106b96;
}

/* ---------------------------------------------------------------------------------- */
.chat-room {
  height: 580px;
  /* display: flex;
    align-items: flex-end; */
}

.chat-room > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.chat-room > div > div {
  display: flex;
  justify-content: space-between;
}

.chat-room > div > div > div {
  display: grid;
  grid-template-columns: 40px 750px;
}
.chat-room > div > div > div > div {
  display: grid;
  padding: 5px;
}
.chat-room > div > div > div > div > div {
  display: flex;
}

.chat-room > div > div > div > div > div > strong {
  color: #29a4db;
  font-size: 1.009rem;
  font-weight: 500;
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat-room > div > div > div > div > div > div {
  display: grid;
}

.chat-room > div > div > div > div > text {
  font-size: 1.009rem;
  word-break: break-word;
}
.chat-room > div > div > div > div > div > span {
  color: #777;
  font-size: 1.009rem;
  margin-left: 1rem;
}

.chat-room-file {
  display: grid !important;
  justify-content: flex-start;
  grid-template-columns: 100%;
}
.chat-room-file > div {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.chat-room-file > div > div {
  width: 100px;
  height: 80px;
  overflow: hidden;
}
.chat-room-file img {
  border: 1px solid #ccc;
  padding: 1px;
  margin: 1px;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.chat-room-file .depart-file {
  border: unset !important;
  /* width: auto !important; */
  cursor: pointer;
  color: #000 !important;
}
.chat-room-file .depart-file:hover {
  text-decoration: underline !important;
}

.chat-room-uploadfile {
  position: relative;
  display: grid !important;
  grid-template-rows: auto !important;
  width: 320px;
}
.chat-room-uploadfile > div {
  display: flex !important;
  flex-wrap: wrap;
  position: relative;
}
.chat-room-uploadfile > div > div {
  position: relative;
  padding: 0px !important;
  margin: 2px;
  width: 100px;
  height: 80px;
  overflow: hidden;
}
.chat-room-uploadfile > div > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.chat-room-uploadfile > div i {
  position: absolute;
  top: calc(50% - 0.799rem);
  right: 2px;
  font-size: 1rem;
  cursor: pointer;
  /* opacity: 0; */
  transition: opacity 0.5s;
}
.chat-room-uploadfile > div .pdf i {
  top: unset !important;
}
.chat-room-uploadfile > div i:hover {
  color: #f00;
}
.chat-room-uploadfile > div:hover i {
  opacity: 1;
}
.chat-room-uploadfile > div > .depart-file {
  padding: 5px 25px 5px 30px !important;
  width: auto;
}
.chat-room-uploadfile > div > .pdf {
  left: 5px !important;
}

/* -------------------------------------------- */
.chat-room-user {
  height: calc(100vh - 200px);
}
.chat-room-user > div > div {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
}
/* .chat-room-user>div>div:nth-last-of-type(1){
	border: unset !important;
	padding-bottom: unset;
} */
.chat-room-user > div > div:nth-last-of-type(2) {
  border: unset !important;
  padding-bottom: unset;
}
.chat-room-user .-chat-profile {
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
}
.chat-room-user .-chat-profile > div {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.chat-room-user .-chat-profile > div > div {
  display: grid;
}
.chat-room-user .-chat-profile > div > div > strong {
  color: #29a4db;
}
.chat-room-user .-chat-text {
  display: grid;
  width: 100%;
  word-break: break-all;
  padding: 0px 5px;
}
.-chat-send {
  display: grid;
  width: 100%;
  padding: 0px 5px;
}
.-chat-send > div:last-of-type {
  display: grid;
  grid-template-columns: 260px 100px;
  align-items: flex-end;
}
.-chat-send > div:last-of-type > div:last-of-type {
  height: 33px;
  margin-bottom: 1.2rem;
}

@media screen and (max-width: 579px) {
  .-chat-send > div:last-of-type {
    grid-template-columns: calc(100% - 100px) 100px;
  }
}

@media screen and (min-width: 1600px) {
  .-chat-send > div:last-of-type {
    display: grid;
    grid-template-columns: 220px 150px;
  }
  .-chat-send > div:last-of-type > div:last-of-type {
    height: 32px;
  }

  .chat-room-user .-chat-profile > div > div > strong {
    font-weight: 500;
  }
}

/*! ================================================================================================================================== */

/*! ================================================================================================================================== */

/*!                                                                                                                                    */

/*!                                                          admin report summary                                                      */

/*!                                                                                                                                    */

/*! ================================================================================================================================== */

/*! ================================================================================================================================== */
.report-chart {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.report-chart > div {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  margin: 0 10px 0 0;
}
.report-chart .chart {
  position: relative;
  display: block;
  padding: 30px 60px 0px 0px;
  margin-bottom: 10px;
}
.chart-x {
  position: absolute;
  width: 70px;
  bottom: 15px;
  right: 0px;
  color: #29a4db;
  font-size: 0.889rem;
}
.chart-y {
  position: absolute;
  top: -5px;
  left: 30px;
  width: 70px;
  color: #29a4db;
  font-size: 0.889rem;
}
.report-chart > .chart:after {
  content: "";
  position: absolute;
  bottom: 3px;
  right: 66px;
  width: calc(100% - 126px);
  height: 2px;
  background-color: #ccc;
}
.report-chart > .chart-note {
  display: grid;
  align-items: center;
  margin-left: 10px;
  width: 100%;
  grid-template-columns: auto auto;
}
.report-chart > .chart-note > div {
  display: grid;
  grid-template-columns: 15px auto;
}
.report-chart > .chart-note > div > i {
  display: block;
  width: 10px;
  height: 10px;
  margin: 5px;
}
.report-chart > .chart-note > div > span {
  margin-left: 5px;
  text-align: left;
  cursor: pointer;
}
.report-chart > .chart-note > div > span:hover {
  color: #29a4db;
  text-decoration: underline;
}
@media screen and (min-width: 1600px) {
  .report-chart > .chart-note {
    margin-left: 15px;
  }
  .report-chart > .chart-note > div > span {
    font-size: 1rem;
  }
  .chart-x {
    font-size: 1rem;
  }
  .chart-y {
    font-size: 1rem;
    left: 20px;
  }
}

.custom-tooltip {
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border: 1px solid #29a4db;
  color: #000;
  padding: 5px;
}
.custom-tooltip .label {
  color: #000 !important;
  font-size: 1rem;
  font-weight: 400;
  padding: 5px;
}
.custom-tooltip .intro {
  color: #000 !important;
  font-size: 13px;
  font-weight: 300;
  padding: 0 5px;
}

.report-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  color: #ffffff;
  margin: 0 10px;
}
.report-header-detail {
  display: flex;
  text-align: left;
  margin: 0 10px;
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
}
.report-header-detail > strong {
  margin: 0 5px;
  color: #29a4db;
  font-weight: 500;
}
.report-header > strong {
  font-size: 1rem;
}
@media screen and (min-width: 1600px) {
  .report-header > span {
    font-size: 1rem;
  }
  .report-header > strong {
    font-weight: 300;
  }
  .report-header-detail {
    font-size: 1rem;
  }
}

/*! ================================================================================================================================== */

/*! ================================================================================================================================== */

/*!                                                                                                                                    */

/*!                                                          admin rating                                                              */

/*!                                                                                                                                    */

/*! ================================================================================================================================== */

/*! ================================================================================================================================== */
.rating-title {
  font-size: 1.899rem;
  font-weight: 500;
}
.rating-chart {
  display: flex;
  flex-direction: row;
}
.rating-chart > div {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.rating-chart > .chart {
  position: relative;
  display: block;
  padding-top: 35px;
  padding-right: 50px;
}
.chart-x {
  position: absolute;
  right: -5px;
  color: #29a4db;
  width: 70px;
}
.rating-chart > .chart > .chart-y {
  position: absolute;
  top: 0px;
  left: 30px;
  color: #29a4db;
  width: 50px;
}

.chart-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
  font-size: 1rem;
}
.chart-chat > span {
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.chart > line {
  border-bottom: 2px solid #ccc;
  width: calc(100% - 60px);
  display: flex;
  margin-top: -5px;
  margin-left: 60px;
}
.chart-chat > div {
  display: grid;
  width: 100%;
  max-height: 500px;
}
.chart-chat > div > div {
  margin: 10px 0;
}
.chart-chat > div > div > div {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
}
.chart-chat > div > div > div {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
}
.chart-chat > div > div > div > span {
  color: #555;
}
.chart-chat > div > div > div > div {
  margin-right: 10px;
}
.chart-chat > div > div > div > div:last-of-type {
  display: grid;
  text-align: left;
}
.chart-chat > div > div > div > div > span {
  display: flex;
  align-items: flex-end;
  font-weight: 400;
}
.chart-chat > div > div > div > div > i {
  font-style: normal;
  font-size: 1rem;
  color: #777;
}
.chart-chat > div > div > div > p {
  font-size: 1rem;
}
@media screen and (min-width: 1600px) {
  .chart-x {
    font-size: 1rem;
  }
  .chart-y {
    font-size: 1rem;
  }

  .chart-chat > div {
    max-height: 600px;
  }
}
