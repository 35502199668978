.zipcodeStyle {
    padding-top: 20px;
    padding-bottom: 20px;
}

.zipcodeStyle input, .zipcodeStyle2 input {
    box-shadow: none;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1rem;
    font-weight: 200;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #777;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 32px;
}

.zipcodeStyle input[disabled], .zipcodeStyle2 input[disabled] {
    background: #cecece;
    color: #333;
}
/* .zipcodeStyle22 input[disabled] {
    background: #eee !important;
} */

.zipcodeStyle2 {
    padding-bottom: 40px;
}